import { Component, OnInit, ViewEncapsulation  } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { DataWpService } from '../data-wp.service';
import { PostI } from '../post.interface';

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.css"],
  encapsulation: ViewEncapsulation.None,
  providers: [DataWpService],
})
export class MenuComponent implements OnInit {
  urlSitio = "https://testweb.vittal.com.ar";

  constructor(private dataWp: DataWpService, private sanitizer: DomSanitizer) {}

  posts$: Observable<PostI[]>;

  menuMobile() {
    //document.getElementsByTagName("contentField1")[0].classList.add("show");
    var elementos = document.getElementsByClassName("btn");
    for (let index = 0; index < elementos.length; index++) {
      document
        .getElementsByClassName("btn")
        [index].setAttribute("aria-expanded", "false");
    }
  }

  /* APERTURA MENU SUPERIOR */
  addClassMenu() {
    var menuBtn = document.getElementById("menu-trigger");
    menuBtn.classList.toggle("clicked");
    var menuDrop = document.getElementById("menu-type");
    menuDrop.classList.toggle("open");
    this.menuMobile();
  }

  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnInit() {
    this.posts$ = this.dataWp.getPosts();
  }
}


