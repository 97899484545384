import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-idoc1',
  templateUrl: './idoc1.component.html',
  styleUrls: ['./idoc1.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class Idoc1Component implements OnInit {


  slides = [
    {img: "assets/img/idoc1-pic-slider-1.jpg", titulo: 'Golpes y traumatismos'},
    {img: "assets/img/idoc1-pic-slider-2.jpg", titulo: 'Alergias y erupciones'},
    {img: "assets/img/idoc1-pic-slider-3.jpg", titulo: 'Malestar gastrointestinal'},
    {img: "assets/img/idoc1-pic-slider-4.jpg", titulo: 'Dolor de cabeza'},
    {img: "assets/img/idoc1-pic-slider-5.jpg", titulo: 'Resfríos'},
    {img: "assets/img/idoc1-pic-slider-6.jpg", titulo: 'Tos'},
  ];

  slideConfig = {
    "slidesToShow": 3, 
    "slidesToScroll": 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: '0px',
          slidesToShow: 1
        }
      }
    ]
  };

  constructor() { }

  ngOnInit() {
  }

}
