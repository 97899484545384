import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class SessionStorageService {
  public isHome$ = new BehaviorSubject(true);
  public isRegister$ = new BehaviorSubject(true);

  public isHome() {
    return this.isHome$.asObservable();
  }

  public setHome() {
    this.isHome$.next(true);
  }

  public clearHome() {
    this.isHome$.next(false);
  }

  public isRegister() {
    return this.isRegister$.asObservable();
  }

  public setRegister() {
    this.isRegister$.next(true);
  }

  public clearRegister() {
    this.isRegister$.next(false);
  }
}