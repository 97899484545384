import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { API_BASE_URL_JSON } from "src/app/data-wp.service";
import { SocioData } from "src/app/shared/socio-data.interface";
import { HttpClient } from "@angular/common/http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { emailData } from "src/app/shared/emailData";
interface Familiar {
  nombre: string;
  apellido: string;
  telefono: string;
}
@Component({
  selector: "app-modificacion-de-datos",
  templateUrl: "./modificacion-de-datos.component.html",
  styleUrls: ["../../socios-gestion-online.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ModificacionDeDatosComponent implements OnInit {
  form: FormGroup;
  selectedValue: string;
  selectedCar: string;

  familiares: Familiar[] = [
    { nombre: "José", apellido: "Perez", telefono: "11325412" },
    { nombre: "Juana", apellido: "Perez", telefono: "11325412" },
    { nombre: "Carlos", apellido: "Perez", telefono: "11325412" },
  ];

  constructor(
    public formBuilder: FormBuilder,
    private http: HttpClient,
    private modalService: NgbModal
  ) {}

  @Output() closeModal = new EventEmitter();
  @ViewChild("modalRespuesta", { static: false }) modalRespuesta: ElementRef;

  datosSocio: SocioData;
  modalMessage: string;

  get nroAfiliado() {
    return this.contactForm.get("nroAfiliado");
  }
  get nombre() {
    return this.contactForm.get("nombre");
  }
  get apellido() {
    return this.contactForm.get("apellido");
  }
  get telefono() {
    return this.contactForm.get("telefono");
  }

  contactForm = this.formBuilder.group({
    //nroAfiliado: ['', [Validators.required]],
    nombre: ["", [Validators.required]],
    apellido: ["", [Validators.required]],
    telefono: ["", [Validators.required, Validators.pattern("[0-9]+")]],
    familiar: ["", [Validators.required]],
  });

  ngOnInit() {
    this.datosSocio = JSON.parse(sessionStorage.getItem("data"));
  }

  handleChange(event) {
    console.log(event);
  }

  submitForm() {
    let formData = new FormData();
    let form = this.contactForm.value;
    formData.append("numero_afiliado", this.datosSocio.socio_numero);
    formData.append("nombre_afiliado", this.datosSocio.socio_nombre);
    formData.append("apellido_afiliado", this.datosSocio.socio_apellido);
    formData.append("email_afiliado", this.datosSocio.socio_email);
    formData.append("dominio_afiliado", this.datosSocio.socio_dominio);
    formData.append("nombre_adherente", this.familiares[form.familiar].nombre);
    formData.append(
      "apellido_adherente",
      this.familiares[form.familiar].apellido
    );
    formData.append("telefono", this.familiares[form.familiar].telefono);
    formData.append("nombre_adherente_nuevo", form.nombre);
    formData.append("apellido_adherente_nuevo", form.apellido);
    formData.append("telefono_nuevo", form.telefono);

    this.datosSocio.socio_dominio.length > 0
      ? formData.append(
          "email_destino",
          emailData[this.datosSocio.socio_dominio]
        )
      : formData.append("email_destino", "cas@vittal.com.ar");

    this.http
      .post<any>(
        API_BASE_URL_JSON + "/contact-form-7/v1/contact-forms/3802/feedback",
        formData
      )
      .subscribe((res) => {
        this.closeModal.emit();
        this.modalMessage = res.message;
        this.openModal(this.modalRespuesta);
      });
  }

  openModal(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form",
    });
  }
}
