import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataWpServiceInstitucional } from '../data-wp.service';
import { Observable } from 'rxjs';
import { PostI } from '../post.interface';
import {DomSanitizer} from '@angular/platform-browser';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Title, Meta } from '@angular/platform-browser';




@Component({
  selector: 'app-institucional',
  templateUrl: './institucional.component.html',
  styleUrls: ['./institucional.component.css'],
  providers: [DataWpServiceInstitucional],
  encapsulation: ViewEncapsulation.None
})
export class InstitucionalComponent implements OnInit {
  closeResult: string;
  product
  title
  constructor( 
    private postsService: DataWpServiceInstitucional,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private titleService: Title, 
    private metaService: Meta
 ) {

    }


    open(content) {
      this.modalService.open(
        content, 
        {ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg'}
        
        )
    }



     posts$: Observable<PostI[]>;

     transform(html) {
      return this.sanitizer.bypassSecurityTrustHtml(html);
    }
      
    
     ngOnInit() {
   
      this.posts$ = this.postsService.getPosts();
      /*DATA PARA SEO*/
      this.posts$.subscribe(data => { 
        /*Levantamos MetaTags*/
        var titleSeo = this.product=data[0].acf.seo.titulo;
        var descripcionSeo = this.product=data[0].acf.seo.descripcion;
        var keywordsSeo = this.product=data[0].acf.seo.keywords;
        /*Seteamos Metatags en header*/
        this.titleService.setTitle( 'Vittal - ' + titleSeo );
        this.metaService.updateTag( {name: 'keywords', content: keywordsSeo} );
        this.metaService.updateTag( {name: 'description', content: descripcionSeo} );
        this.metaService.updateTag( {name: 'robots', content: 'index, follow'} );
       } 
      )   


   
     }

}
