import { Component, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { SessionStorageService } from 'src/app/services/session-storage.service';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    private sessionstorageService: SessionStorageService
  ) {}

  isMobile: boolean;
  screenSize;
  isHome;
  isRegister;

  ngOnInit() {
    this.screenSize = window.innerWidth;
    window.innerWidth < 760 ? (this.isMobile = true) : (this.isMobile = false);
    this.sessionstorageService.setHome();
    this.sessionstorageService.clearRegister();
    this.sessionstorageService.isHome().subscribe((e) => {
      this.isHome = e;
    });
    this.sessionstorageService.isRegister().subscribe((e) => {
      this.isRegister = e;
    });
  }

  /* Funciones para cambiar backs lateral en hovers login */
  hoverSocio() {
    document.getElementById("imgHover").classList.remove("empresaBack");
    document.getElementById("imgHover").classList.add("sociosBack");
  }
  hoverEmpresa() {
    document.getElementById("imgHover").classList.remove("sociosBack");
    document.getElementById("imgHover").classList.add("empresaBack");
  }
  /*Funcion para abrir modal problemas de acceso*/
  openModalProblema(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-problemas",
    });
  }
  /* Funcion para mostrar form socios */
  mostrarSocio() {
    document.getElementById("imgHover").classList.remove("empresaBack");
    document.getElementById("imgHover").classList.add("sociosBack");
    document.getElementById("loginSocios").classList.add("show");
    document.getElementById("volverGestion").classList.add("show");
    document.getElementById("loginFront").classList.remove("show");
    document.getElementById("loginEmpresa").classList.remove("show");
    this.sessionstorageService.clearHome();
  }
  /* Funcion para mostrar form empresa */
  mostrarEmpresa() {
    document.getElementById("imgHover").classList.remove("sociosBack");
    document.getElementById("imgHover").classList.add("empresaBack");
    document.getElementById("loginEmpresa").classList.add("show");
    document.getElementById("volverGestion").classList.add("show");
    document.getElementById("loginFront").classList.remove("show");
    document.getElementById("loginSocios").classList.remove("show");
    this.sessionstorageService.clearHome();
  }
  /* Funcion para volver */
  volverGestion() {
    this.sessionstorageService.setHome();
    this.sessionstorageService.clearRegister();
    this.modalService.dismissAll();
    document.getElementById("volverGestion").classList.remove("show");
    document.getElementById("loginSocios").classList.remove("show");
    document.getElementById("loginEmpresa").classList.remove("show");
    document.getElementById("contentRegistro").classList.remove("show");
    document.getElementById("registroSocios").classList.remove("show");
    document.getElementById("registroEmpresa").classList.remove("show");
    document.getElementById("loginFront").classList.add("show");
    document.getElementById("contentLogin").classList.add("show");
    document.getElementById("imgHover").classList.remove("empresaBack");
    document.getElementById("imgHover").classList.remove("sociosBack");
    if (this.isMobile) {
      document.getElementById("titulosMobile").classList.add("show");
    }
  }
  /* Mostrar registro desde popup problemas socios */
  mostrarRegistroSocios() {
    document.getElementById("contentRegistro").classList.add("show");
    document.getElementById("registroSocios").classList.add("show");
    document.getElementById("contentLogin").classList.remove("show");
    document.getElementById("titulosMobile").classList.add("show");
    this.sessionstorageService.clearHome();
  }
  /* Mostrar registro desde popup problemas Empresas */
  mostrarRegistroEmpresa() {
    document.getElementById("contentRegistro").classList.add("show");
    document.getElementById("registroEmpresa").classList.add("show");
    document.getElementById("contentLogin").classList.remove("show");
    document.getElementById("titulosMobile").classList.add("show");
    this.sessionstorageService.clearHome();
  }
}
