import { Component, OnInit, Input } from '@angular/core';
import { DataWpServicePromos } from '../../../data-wp.service';
import { PostI } from '../../../post.interface';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-nav-promos',
  templateUrl: './nav-promos.component.html',
  styleUrls: ['./nav-promos.component.css'],
  providers: [DataWpServicePromos]
})
export class NavPromosComponent implements OnInit {
  @Input() slugId;

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 1
        }
      }
    ]
  };
  

  posts: PostI[];
  constructor( private postsService: DataWpServicePromos, private router: Router) { }
  ngOnInit() {
    this.postsService.getPosts().subscribe( 
      (posts: PostI[]) => this.posts = posts, 
      (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));
  }
  selectPost(slug) {
  	this.router.navigate([slug]);
  }


}
