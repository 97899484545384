import { Component, OnInit } from '@angular/core';
import { DataWpServiceChatbot } from '../../data-wp.service';
import { Observable } from 'rxjs';
import { PostI } from '../../post.interface';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css'],
  providers: [DataWpServiceChatbot]
})
export class ChatbotComponent implements OnInit {
  product
  title

  constructor(
    private postsService: DataWpServiceChatbot
  ) { }

  posts$: Observable<PostI[]>;

  ngOnInit() {

    this.posts$ = this.postsService.getPosts();
    this.posts$.subscribe(data => { 
      /*Levantamos Data de chatbot*/
      var habilitar = this.product=data[0].acf.chatbot.habilitar;
      var key = this.product=data[0].acf.chatbot.key;
      if(habilitar){
          //CHATBOT
          var Tawk_API=Tawk_API||{}; var Tawk_LoadStart=new Date();
          (function(){
          var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
          s1.async=true;
          s1.src='https://embed.tawk.to/'+ key +'/default';
          s1.charset='UTF-8';
          s1.setAttribute('crossorigin','*');
          s0.parentNode.insertBefore(s1,s0);
          })();
      }
      
     } 
    )  



  }

}
