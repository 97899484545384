import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { API_BASE_URL_V } from "src/app/data-wp.service";

import { SocioData } from "src/app/shared/socio-data.interface";

@Component({
  selector: "app-validacion-socios",
  templateUrl: "./validacion-socios.component.html",
  styleUrls: ["./validacion-socios.component.css"],
})
export class ValidacionSociosComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router
  ) {}

  socio_guid;
  validateCode;
  datosSocio: SocioData;
  ngOnInit() {
    this.validateCode = this.route.params.subscribe((params) => {
      this.socio_guid = params["id"];
    });
    console.log(this.socio_guid);
    this.sendValidationCode(this.socio_guid);
  }

  sendValidationCode(socio_guid) {
    this.http
      .post<any>(API_BASE_URL_V + "/socio/validate", { socio_guid: socio_guid })
      .subscribe((response) => {
        if (response.success) {
          this.datosSocio = response.data;
          this.saveAuthData(this.datosSocio);
        } else {
          console.log(response.message);
        }
      });
  }

  private saveAuthData(data) {
    sessionStorage.setItem("data", JSON.stringify(data));
    sessionStorage.setItem("isLogged", "true");
    this.router.navigate(["/atencion-al-socio/gestion-online/socios"]);
  }
}
