import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { SocioData } from '../shared/socio-data.interface';

@Component({
  selector: "app-gestion-online",
  templateUrl: "./gestion-online.component.html",
  styleUrls: ["./gestion-online.component.css"],
})
export class GestionOnlineComponent implements OnInit {
  datosSocio: SocioData;
  socio_nombre: string;
  socio_apellido: string;
  socio_numero: string;
  socio_dominio: string;

  constructor(private router: Router) {}

  ngOnInit() {
    if (sessionStorage.getItem("data")) {
      this.datosSocio = JSON.parse(sessionStorage.getItem("data"));

      if (this.datosSocio.socio_tipo_id == 1) {
        this.router.navigate(["/gestion-online/socios"]);
      }
    }
  }
}
