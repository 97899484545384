import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { API_BASE_URL_JSON } from 'src/app/data-wp.service';
import { emailData } from "src/app/shared/emailData";
import { EmpresaData } from "src/app/shared/empresa-data.interface";

@Component({
  selector: "app-reclamo-de-cobrador-empresas",
  templateUrl: "./reclamo-de-cobrador-empresas.component.html",
  styleUrls: ["../../empresa-gestion-online.component.css"],
})
export class ReclamoDeCobradorEmpresasComponent implements OnInit {
  constructor(public formBuilder: FormBuilder, private http: HttpClient) {}

  @Output() closeModal = new EventEmitter();

  get comentario() {
    return this.contactForm.get("comentario");
  }

  datosEmpresa: EmpresaData;

  contactForm = this.formBuilder.group({
    comentario: ["", [Validators.required]],
  });

  ngOnInit() {
    this.datosEmpresa = JSON.parse(sessionStorage.getItem("data"));
  }

  submitForm() {
    let formData = new FormData();
    let form = this.contactForm.value;
    formData.append("empresa_codigo", this.datosEmpresa.empresa_codigo);
    this.datosEmpresa.empresa_nombre.length > 0
      ? formData.append(
          "empresa_razon_social",
          this.datosEmpresa.empresa_nombre
        )
      : formData.append("empresa_razon_social", "NO posee razón social");
    formData.append("empresa_cuit", this.datosEmpresa.empresa_cuit);
    formData.append("empresa_dominio", this.datosEmpresa.empresa_dominio);
    formData.append("empresa_email", this.datosEmpresa.empresa_email);
    formData.append("comentario", form.comentario);
    this.datosEmpresa.empresa_dominio.length > 0
      ? formData.append(
          "email_destino",
          emailData[this.datosEmpresa.empresa_dominio]
        )
      : formData.append("email_destino", "cas@vittal.com.ar");

    this.http
      .post<any>(
        API_BASE_URL_JSON + "/contact-form-7/v1/contact-forms/3814/feedback",
        formData
      )
      .subscribe((res) => {
        if ((res.status = "mail_sent")) {
          this.closeModal.emit();
          alert(res.message);
        } else {
          alert(res.message);
        }
      });
  }
}
