import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-cobertura-de-eventos',
  templateUrl: './cobertura-de-eventos.component.html',
  styleUrls: ['./cobertura-de-eventos.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CoberturaDeEventosComponent implements OnInit {

  slides2 = [
    {img: "assets/img/cobertura-de-eventos-pic-2.jpg", titulo: 'Médicos especializados para cobertura de eventos multitudinarios:', bajada: 'El personal de vittal cuenta con la experiencia necesaria para atender situaciones de emergencia en espacios amplios y multitudinarios. Los móviles para eventos son de alta complejidad para atender todo tipo de casos en todo momento y moverse a cualquier distancia si la situación lo amerita.'},
    {img: "assets/img/cobertura-de-eventos-pic-3.jpg", titulo: 'Cobertura con helicóptero sanitario:', bajada: 'Permite la llegada a zonas alejadas o de difícil acceso acortando los tiempos de atención. Somos la única empresa con helicóptero habilitado para traslados sanitarios en Argentina. Contamos con un equipo de Socorristas de la Cruz Roja, entrenados para auxiliar a personas en situación de riesgo y/o peligro, brindando una prestación sistematizada de técnicas de primeros auxilios para un determinado evento.'},
    {img: "assets/img/cobertura-de-eventos-pic-4.jpg", titulo: 'Socorristas:', bajada: 'Contamos con un equipo de Socorristas de la Cruz Roja, entrenados para auxiliar a personas en situación de riesgo y/o peligro, brindando una prestación sistematizada de técnicas de primeros auxilios para un determinado evento.'},
    {img: "assets/img/cobertura-de-eventos-pic-5.jpg", titulo: 'Stands de capacitación y asistencia:', bajada: 'Disponemos de equipos especializados de Fundación vittal para realizar cursos rápidos, despejar dudas y enseñar tareas de reanimación a adultos y chicos.'}
    
  ];


  slideConfig = {
    "slidesToShow": 3, 
    "slidesToScroll": 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: '0px',
          slidesToShow: 1
        }
      }
    ]
  };


  constructor() { }

  ngOnInit() {
  }

}
