import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { phoneNumberValidator } from './validators/phone-validator'
import { API_BASE_URL_JSON } from "../data-wp.service";

@Component({
  selector: "app-contacto",
  templateUrl: "./contacto.component.html",
  styleUrls: ["./contacto.component.css"],
})
export class ContactoComponent implements OnInit {
  form: FormGroup;
  errorMessage;
  constructor(public formBuilder: FormBuilder, private http: HttpClient) {}

  get nombres() {
    return this.contactForm.get("nombres");
  }
  get apellido() {
    return this.contactForm.get("apellido");
  }
  get email() {
    return this.contactForm.get("email");
  }
  get telefono_car() {
    return this.contactForm.get("telefono_car");
  }
  get telefono_num() {
    return this.contactForm.get("telefono_num");
  }
  get mensaje() {
    return this.contactForm.get("mensaje");
  }

  areas: any = [
    "Planes de Salud",
    "Atención al Socios",
    "Areas Protegidas",
    "Empresas",
    "Entidades de Salud",
    "Servicios Aeromédicos",
    "Franquicias",
  ];

  provincias: any = ["CABA", "GBA", "Buenos Aires", "Santa Fe", "Cordoba"];

  ciudades: any = ["CABA", "La Plata", "Rosario"];

  contactForm = this.formBuilder.group({
    area: ["", [Validators.required]],
    nombres: ["", [Validators.required]],
    apellido: ["", [Validators.required]],
    email: [
      "",
      [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$"),
      ],
    ],
    telefono_car: ["", [Validators.required, phoneNumberValidator]],
    telefono_num: ["", [Validators.required, phoneNumberValidator]],
    mensaje: ["", [Validators.required, Validators.minLength(20)]],
  });

  ngOnInit() {}

  sendForm() {
    document.getElementById("sendOk").classList.add("show");
  }

  submitForm() {
    if (!this.contactForm.valid) {
      alert("Revisar los campos");
    } else {
      var formData: any = new FormData();

      formData.append("area", this.contactForm.get("area").value);
      formData.append("nombres", this.contactForm.get("nombres").value);
      formData.append("apellido", this.contactForm.get("apellido").value);
      formData.append("email", this.contactForm.get("email").value);
      formData.append(
        "telefono_car",
        this.contactForm.get("telefono_car").value
      );
      formData.append(
        "telefono_num",
        this.contactForm.get("telefono_num").value
      );
      formData.append("mensaje", this.contactForm.get("mensaje").value);
      switch(this.contactForm.get("area").value){
        case "Planes de Salud":{
          formData.append("email_area",  "asociarse@vittal.com.ar");
          break;
        }
        case "Atención al Socios":{
          formData.append("email_area",  "cas@vittal.com.ar");
          break;
        }
        case "Areas Protegidas":{
          formData.append("email_area",  "empresas@vittal.com.ar");
          break;
        }
        case "Empresas":{
          formData.append("email_area",  "grandesclientes@vittal.com.ar");
          break;
        }
        case "Entidades de Salud":{
          formData.append("email_area",  "pvolponi@vittal.com.ar");
          break;
        }
        case "Servicios Aeromédicos":{
          formData.append("email_area",  "vuelos@vittal.com.ar");
          break;
        }
        case "Franquicias":{
          formData.append("email_area",  "franquicias@vittal.com.ar");
          break;
        }     
      }
        
      this.http
        .post<any>(
          API_BASE_URL_JSON + "/contact-form-7/v1/contact-forms/1217/feedback",
          formData
        )
        .subscribe((response) => {
          if ((response.status = "mail_sent") && !response.invalidFields) {
            this.sendForm();
          } else {
            this.errorMessage = response.message;
            document.getElementById("sendError").classList.add("show");
          }
        });
    }
  }

  otraConsulta() {
    location.reload();
    return false;
  }

  spanTop() {
    document.getElementById("areaSpan").classList.add("top");
    document.getElementById("contentSelect").classList.add("ok");
  }
}
