import { Component, OnInit, OnDestroy } from '@angular/core';
import {Router, NavigationStart, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy{
  subscription: Subscription;
  showTemplate: boolean = false;
  
  constructor( private router: Router ){
    // on route change to '/login', set the variable showTemplate to false
    router.events.forEach((event) => {
      var urlAhora = event['url'];
      if (event instanceof NavigationStart) {
        if (urlAhora.includes("landing") ) {
          this.showTemplate = false;
        } else {
          // console.log("NU")
          this.showTemplate = true;
        }
      }
    });
  }
  ngOnInit(){
    this.subscription = this.router.events
      .pipe(
        filter( event => event instanceof NavigationEnd )
      )
      .subscribe( () => window.scrollTo(0,0));
      
      
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
    
  }

}

