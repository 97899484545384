import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataWpServiceFranquicias } from '../data-wp.service';
import { Observable } from 'rxjs';
import { PostI } from '../post.interface';
import {DomSanitizer} from '@angular/platform-browser';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-franquicias',
  templateUrl: './franquicias.component.html',
  styleUrls: ['./franquicias.component.css'],
  providers: [DataWpServiceFranquicias],
  encapsulation: ViewEncapsulation.None
})
export class FranquiciasComponent implements OnInit {
  product
  title
  constructor(
    private postsService: DataWpServiceFranquicias, 
    private sanitizer: DomSanitizer,
    private titleService: Title, 
    private metaService: Meta) { }

  posts$: Observable<PostI[]>;

  transform(html) {
   return this.sanitizer.bypassSecurityTrustHtml(html);
 }

  ngOnInit() {

    this.posts$ = this.postsService.getPosts();
    this.posts$.subscribe(data => { 
      /*Levantamos MetaTags*/
      var titleSeo = this.product=data[0].acf.seo.titulo;
      var descripcionSeo = this.product=data[0].acf.seo.descripcion;
      var keywordsSeo = this.product=data[0].acf.seo.keywords;
      /*Seteamos Metatags en header*/
      this.titleService.setTitle( 'Vittal - ' + titleSeo );
      this.metaService.updateTag( {name: 'keywords', content: keywordsSeo} );
      this.metaService.updateTag( {name: 'description', content: descripcionSeo} );
      this.metaService.updateTag( {name: 'robots', content: 'index, follow'} );
     } 
    )  

  }


  slideConfig = {
    "slidesToShow": 3, 
    "slidesToScroll": 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: '0px',
          slidesToShow: 1
        }
      }
    ]
  };



}
