import { Component, OnInit, ViewEncapsulation, OnDestroy  } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DataWpServicePromos } from '../../../data-wp.service';
import { PostI } from '../../../post.interface';
import {DomSanitizer} from '@angular/platform-browser';
import { Observable  } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
@Component({
  selector: "app-promo-single",
  templateUrl: "./promo-single.component.html",
  styleUrls: ["./promo-single.component.css"],
  providers: [DataWpServicePromos],
  encapsulation: ViewEncapsulation.None,
})
export class PromoSingleComponent implements OnInit {
  product;
  title;
  posts$: Observable<PostI[]>;

  post: PostI;
  mobile: boolean;
  constructor(
    private postsService: DataWpServicePromos,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private metaService: Meta
  ) {}

  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getPost(slug) {
    this.postsService.getPost(slug).subscribe((res) => {
      this.post = res[0];
    });
  }

  ngOnInit() {
    window.innerWidth < 500 ? (this.mobile = true) : (this.mobile = false);
    this.route.params.forEach((params: Params) => {
      let slug = params["slug"];
      this.getPost(slug);
      this.posts$ = this.postsService.getPost(slug);
    });

    this.posts$.subscribe((data) => {      
      /*Levantamos MetaTags*/
      var titleSeo = (this.product = data[0].acf.seo.titulo);
      var descripcionSeo = (this.product = data[0].acf.seo.descripcion);
      var keywordsSeo = (this.product = data[0].acf.seo.keywords);
      /*Seteamos Metatags en header*/
      this.titleService.setTitle("Vittal - " + titleSeo);
      this.metaService.updateTag({ name: "keywords", content: keywordsSeo });
      this.metaService.updateTag({
        name: "description",
        content: descripcionSeo,
      });
      this.metaService.updateTag({ name: "robots", content: "index, follow" });
    });
  }
}
