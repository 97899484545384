import { Component, OnInit } from '@angular/core';
import { DataWpServiceLandings } from '../data-wp.service';
import { PostI } from '../post.interface';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-landings',
  templateUrl: './landings.component.html',
  styleUrls: ['./landings.component.css'],
  providers: [DataWpServiceLandings]
})
export class LandingsComponent implements OnInit {

  posts: PostI[];
  constructor( private postsService: DataWpServiceLandings, private router: Router ) { }
  ngOnInit() {
    this.router.navigate([''])

    this.postsService.getPosts().subscribe( 
      (posts: PostI[]) => this.posts = posts, 
      (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));
  }
  selectPost(slug) {
  	this.router.navigate([slug]);
  }

}
