export const emailData = {
  AVA: "cas@vittal.com.ar",
  BLO: "cas@vittal.com.ar",
  CTR: "cas@vittal.com.ar",
  VIE: "cas@vittal.com.ar",
  LPL: "cas@vittal.com.ar",
  MDQ: "cas@vittal.com.ar",
  ROS: "info@rosario.vittal.com.ar",
  TUC: "admcontable@tucuman.vittal.com.ar",
  VIM: "pvidondo@vittal.com.ar",
  CTM: "info@catamarca.vittal.com.ar",
  SLU: "pgatica@sanluis.vittal.com.ar",
};
