import { Component, OnInit, Input, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { phoneNumberValidator } from "../../../contacto/validators/phone-validator";
import { HttpClient } from "@angular/common/http";
import { DataWpServicePrecios } from "../../../data-wp.service";
import { PostI } from "../../../post.interface";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-form-cotizadores",
  templateUrl: "./form-cotizadores.component.html",
  styleUrls: ["./form-cotizadores.component.css"],
  providers: [DataWpServicePrecios],
})
export class FormCotizadoresComponent implements OnInit {
  @Input() codePrice; /*INPUT CON CODIGO DE PRECIO*/
  @Input() codeForm: number; /*INPUT CON CODIGO DE FORMULARIO PARA ENVIAR*/
  @Input() tituloForm: string; /*INPUT CON TITULO DE FORMULARIO*/
  @Input() isMobile: boolean;
  @Input() isBitrix: boolean;
  form: FormGroup; /* FORMUALRIO VALIDADORES, ETC */
  posts: PostI[]; /*Interfaz para levantar data precios de planes*/
  closeResult: string; /* SETEO DE VARIABLE PARA MODAL */
  iconoIdoc: string;
  items = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
  verifEdad1: boolean;
  verifEdad2: boolean;
  verifEdad3: boolean;
  verifEdad4: boolean;
  constructor(
    public formBuilder: FormBuilder,
    private http: HttpClient,
    private postsService: DataWpServicePrecios,
    private router: Router,
    private modalService: NgbModal
  ) {}

  get nombre() {
    return this.contactForm.get("nombre");
  }
  get apellido() {
    return this.contactForm.get("apellido");
  }
  get email() {
    return this.contactForm.get("email");
  }
  get telefono() {
    return this.contactForm.get("telefono");
  }
  get localidad() {
    return this.contactForm.get("localidad");
  }
  get edad1() {
    return this.contactForm.get("edad1");
  }
  get edad2() {
    return this.contactForm.get("edad2");
  }
  get edad3() {
    return this.contactForm.get("edad3");
  }
  get edad4() {
    return this.contactForm.get("edad4");
  }
  get edad5() {
    return this.contactForm.get("edad5");
  }
  get edad6() {
    return this.contactForm.get("edad6");
  }
  get edad7() {
    return this.contactForm.get("edad7");
  }
  get edad8() {
    return this.contactForm.get("edad8");
  }
  get edad9() {
    return this.contactForm.get("edad9");
  }
  get edad10() {
    return this.contactForm.get("edad10");
  }

  contactForm = this.formBuilder.group({
    nombre: ["", [Validators.required]],
    apellido: ["", [Validators.required]],
    email: [
      "",
      [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$"),
      ],
    ],
    telefono: ["", [Validators.required, Validators.pattern("^[0-9]+$")]],
    localidad: ["", [Validators.required]],
    select: ["", [Validators.required]],
    edad1: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
    edad2: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
    edad3: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
    edad4: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
    edad5: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
    edad6: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
    edad7: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
    edad8: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
    edad9: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
    edad10: ["", [Validators.maxLength(3), Validators.pattern("^[0-9]{1,3}$")]],
  });

  spanTop() {
    document.getElementById("areaSpan").classList.add("top");
    document.getElementById("contentSelect").classList.add("ok");
  }
  spanTopEdades() {
    document.getElementById("edadesSpan").classList.add("top");
  }

  valor = 0;
  selectHijos(value) {
    this.valor = value;
    if (value == 1) {
      setTimeout(() => {
        document.getElementById("contentField1").classList.add("show");
      }, 10);
      this.verifEdad1 = true;
      for (let i = 2; i < 11; i++) {
        this.contactForm.controls[`edad${i}`].clearValidators();
        this.contactForm.controls[`edad${i}`].updateValueAndValidity();
      }
    }
    if (value >= 2) {
      setTimeout(() => {
        document.getElementById("contentField2").classList.add("show");
      }, 10);
      this.contactForm.controls["edad2"].setValidators([
        Validators.required,
        Validators.maxLength(2),
        Validators.pattern("^[0-9]{1,2}$"),
      ]);
      for (let i = 3; i < 11; i++) {
        this.contactForm.controls[`edad${i}`].clearValidators();
        this.contactForm.controls[`edad${i}`].updateValueAndValidity();
      }
    }
    if (value >= 3) {
      setTimeout(() => {
        document.getElementById("contentField3").classList.add("show");
      }, 10);
      this.contactForm.controls["edad3"].setValidators([
        Validators.required,
        Validators.maxLength(2),
        Validators.pattern("^[0-9]{1,2}$"),
      ]);
      for (let i = 4; i < 11; i++) {
        this.contactForm.controls[`edad${i}`].clearValidators();
        this.contactForm.controls[`edad${i}`].updateValueAndValidity();
      }
    }
    if (value >= 4) {
      setTimeout(() => {
        document.getElementById("contentField4").classList.add("show");
      }, 10);
      this.contactForm.controls["edad4"].setValidators([
        Validators.required,
        Validators.maxLength(2),
        Validators.pattern("^[0-9]{1,2}$"),
      ]);
      for (let i = 5; i < 11; i++) {
        this.contactForm.controls[`edad${i}`].clearValidators();
        this.contactForm.controls[`edad${i}`].updateValueAndValidity();
      }
    }
    if (value >= 5) {
      setTimeout(() => {
        document.getElementById("contentField5").classList.add("show");
      }, 10);
      this.contactForm.controls["edad5"].setValidators([
        Validators.required,
        Validators.maxLength(2),
        Validators.pattern("^[0-9]{1,2}$"),
      ]);
      for (let i = 6; i < 11; i++) {
        this.contactForm.controls[`edad${i}`].clearValidators();
        this.contactForm.controls[`edad${i}`].updateValueAndValidity();
      }
    }
    if (value >= 6) {
      setTimeout(() => {
        document.getElementById("contentField6").classList.add("show");
      }, 10);
      this.contactForm.controls["edad6"].setValidators([
        Validators.required,
        Validators.maxLength(2),
        Validators.pattern("^[0-9]{1,2}$"),
      ]);
      for (let i = 7; i < 11; i++) {
        this.contactForm.controls[`edad${i}`].clearValidators();
        this.contactForm.controls[`edad${i}`].updateValueAndValidity();
      }
    }
    if (value >= 7) {
      setTimeout(() => {
        document.getElementById("contentField7").classList.add("show");
      }, 10);
      this.contactForm.controls["edad7"].setValidators([
        Validators.required,
        Validators.maxLength(2),
        Validators.pattern("^[0-9]{1,2}$"),
      ]);
      for (let i = 8; i < 11; i++) {
        this.contactForm.controls[`edad${i}`].clearValidators();
        this.contactForm.controls[`edad${i}`].updateValueAndValidity();
      }
    }
    if (value >= 8) {
      setTimeout(() => {
        document.getElementById("contentField8").classList.add("show");
      }, 10);
      this.contactForm.controls["edad8"].setValidators([
        Validators.required,
        Validators.maxLength(2),
        Validators.pattern("^[0-9]{1,2}$"),
      ]);
      for (let i = 9; i < 11; i++) {
        this.contactForm.controls[`edad${i}`].clearValidators();
        this.contactForm.controls[`edad${i}`].updateValueAndValidity();
      }
    }
    if (value >= 9) {
      setTimeout(() => {
        document.getElementById("contentField9").classList.add("show");
      }, 10);
      this.contactForm.controls["edad9"].setValidators([
        Validators.required,
        Validators.maxLength(2),
        Validators.pattern("^[0-9]{1,2}$"),
      ]);
      for (let i = 10; i < 11; i++) {
        this.contactForm.controls[`edad${i}`].clearValidators();
        this.contactForm.controls[`edad${i}`].updateValueAndValidity();
      }
    }
    if (value == 10) {
      setTimeout(() => {
        document.getElementById("contentField10").classList.add("show");
      }, 10);
      this.contactForm.controls["edad10"].setValidators([
        Validators.required,
        Validators.maxLength(2),
        Validators.pattern("^[0-9]{1,2}$"),
      ]);
    }
  }

  ngOnInit() {
    console.log(this.isBitrix);

    this.postsService.getPosts().subscribe(
      (posts: PostI[]) => {
        this.posts = [...posts];
        this.posts[0] = posts[4];
        this.posts[1] = posts[2];
        this.posts[2] = posts[1];
        this.posts[4] = posts[0];
        this.iconoIdoc = this.isMobile
          ? "post.acf.icono_blanco"
          : "post.acf.icono_violeta";
        console.log(this.posts);
      },
      (err: HttpErrorResponse) =>
        err.error instanceof Error
          ? console.log("An error occurred:", err.error.message)
          : console.log(
              `Backend returned code ${err.status}, body was: ${err.error}`
            )
    );
    console.log("es mobil:" + this.isMobile);
    console.log(this.tituloForm);
    console.log(this.codePrice);
  }
  selectPost(slug) {
    this.router.navigate([slug]);
  }

  /*TEST FORM EN FUNCION*/
  alerta(data) {
    alert(data);
  }

  calcularPrecios(
    nombre,
    price_1p,
    price_2p,
    price_3p,
    price_4p,
    price_p_adicional,
    price_copago,
    precio_adicional_65a69,
    precio_adicional_70a74,
    precio_adicional_75a79,
    precio_adicional_80mas
  ) {
    console.log("Ejecutamos el calculo antes de enviar por mail " + nombre);
    /*SETEOS*/
    var menor = 0;
    var adult = 0;
    var adult64a69 = 0;
    var adult70a74 = 0;
    var adult75a79 = 0;
    var adult80mas = 0;

    /*PRECIOS*/
    price_1p = parseFloat(price_1p);
    price_2p = parseFloat(price_2p);
    price_3p = parseFloat(price_3p);
    price_4p = parseFloat(price_4p);
    price_p_adicional = parseFloat(price_p_adicional);
    price_copago = parseFloat(price_copago);
    precio_adicional_65a69 = parseFloat(precio_adicional_65a69);
    precio_adicional_70a74 = parseFloat(precio_adicional_70a74);
    precio_adicional_75a79 = parseFloat(precio_adicional_75a79);
    precio_adicional_80mas = parseFloat(precio_adicional_80mas);

    /*EDADES*/
    var calcEdad1 = parseFloat(this.contactForm.get("edad1").value);
    var calcEdad2 = parseFloat(this.contactForm.get("edad2").value);
    var calcEdad3 = parseFloat(this.contactForm.get("edad3").value);
    var calcEdad4 = parseFloat(this.contactForm.get("edad4").value);
    var calcEdad5 = parseFloat(this.contactForm.get("edad5").value);
    var calcEdad6 = parseFloat(this.contactForm.get("edad6").value);
    var calcEdad7 = parseFloat(this.contactForm.get("edad7").value);
    var calcEdad8 = parseFloat(this.contactForm.get("edad8").value);
    var calcEdad9 = parseFloat(this.contactForm.get("edad9").value);
    var calcEdad10 = parseFloat(this.contactForm.get("edad10").value);

    /* CALCULA VALORES A MOTRAR EN PLACA DE RESULTADOS */
    if (this.valor) {
      for (var i = 0; i < this.valor; i++) {
        var val_item = i + 1;
        var people_value = 0;
        if (val_item == 1) {
          people_value = calcEdad1;
        }
        if (val_item == 2) {
          people_value = calcEdad2;
        }
        if (val_item == 3) {
          people_value = calcEdad3;
        }
        if (val_item == 4) {
          people_value = calcEdad4;
        }
        if (val_item == 5) {
          people_value = calcEdad5;
        }
        if (val_item == 6) {
          people_value = calcEdad6;
        }
        if (val_item == 7) {
          people_value = calcEdad7;
        }
        if (val_item == 8) {
          people_value = calcEdad8;
        }
        if (val_item == 9) {
          people_value = calcEdad9;
        }
        if (val_item == 10) {
          people_value = calcEdad10;
        }
        var edad_avanzada = false;

        if (people_value > 11) {
          if (people_value > 64 && people_value < 70) {
            adult64a69++;
            edad_avanzada = true;
          }

          if (people_value > 69 && people_value < 75) {
            adult70a74++;
            edad_avanzada = true;
          }

          if (people_value > 74 && people_value < 80) {
            adult75a79++;
            edad_avanzada = true;
          }

          if (people_value > 79) {
            adult80mas++;
            edad_avanzada = true;
          }

          if (edad_avanzada == false) {
            adult++;
          }
        } else {
          menor++;
        }
      }

      //Suma todos los adultos
      adult = adult + adult64a69 + adult70a74 + adult75a79 + adult80mas + menor;
      if (adult == 1) {
        var price = price_1p;
      }
      if (adult == 2) {
        var price = price_2p;
      }
      if (adult == 3) {
        var price = price_3p;
      }
      if (adult == 4) {
        var price = price_4p;
      }
      if (adult == 5) {
        var price = price_4p + price_p_adicional;
      }
      if (adult == 6) {
        var price = price_4p + price_p_adicional * 2;
      }
      if (adult == 7) {
        var price = price_4p + price_p_adicional * 3;
      }
      if (adult == 8) {
        var price = price_4p + price_p_adicional * 4;
      }
      if (adult == 9) {
        var price = price_4p + price_p_adicional * 5;
      }
      if (adult == 10) {
        var price = price_4p + price_p_adicional * 6;
      }
      /*ADICIONALES MAYORES*/
      if (adult64a69 > 0) {
        var price = price + precio_adicional_65a69 * adult64a69;
      }
      if (adult70a74 > 0) {
        var price = price + precio_adicional_70a74 * adult70a74;
      }
      if (adult75a79 > 0) {
        var price = price + precio_adicional_75a79 * adult75a79;
      }
      if (adult80mas > 0) {
        var price = price + precio_adicional_80mas * adult80mas;
      }
    }

    /*MOSTRAR RESULTADOS EN PLACA*/
    document.getElementById("nombrePlan").innerHTML = "Plan " + nombre;
    document.getElementById("resultadoCotizacion").innerHTML = price;

    /* AGREGA CLASE PARA MOSTRAR PLACA */
    document.getElementById("contentAll").classList.add("enviado");
  }

  nombrePersona = this.contactForm.get("nombre").value;
  apellidoPersona = this.contactForm.get("apellido").value;
  emailPersona = this.contactForm.get("email").value;
  telefonoPersona = this.contactForm.get("telefono").value;
  localidadPersona = this.contactForm.get("localidad").value;
  datoFormEdad1 = 0;
  datoFormEdad2 = 0;
  datoFormEdad3 = 0;
  datoFormEdad4 = 0;
  datoFormEdad5 = 0;
  datoFormEdad6 = 0;
  datoFormEdad7 = 0;
  datoFormEdad8 = 0;
  datoFormEdad9 = 0;
  datoFormEdad10 = 0;

  /*ENVIA EMAIL*/
  submitForm(formCode) {
    if (!this.contactForm.valid) {
      alert("Revisar los campos");
    } else {
      /*var formData: any = new FormData();
      formData.append("nombre", this.contactForm.get('nombre').value);
      formData.append("apellido", this.contactForm.get('apellido').value);
      formData.append("email", this.contactForm.get('email').value);    
      formData.append("telefono", this.contactForm.get('telefono').value);    
      formData.append("localidad", this.contactForm.get('localidad').value);   
      formData.append("edad1", this.contactForm.get('edad1').value);    
      formData.append("edad2", this.contactForm.get('edad2').value);    
      formData.append("edad3", this.contactForm.get('edad3').value);    
      formData.append("edad4", this.contactForm.get('edad4').value);    
      formData.append("edad5", this.contactForm.get('edad5').value);    
      formData.append("edad6", this.contactForm.get('edad6').value);    
      formData.append("edad7", this.contactForm.get('edad7').value);    
      formData.append("edad8", this.contactForm.get('edad8').value);    
      formData.append("edad9", this.contactForm.get('edad9').value);    
      formData.append("edad10", this.contactForm.get('edad10').value);  */

      this.nombrePersona = this.contactForm.get("nombre").value;
      this.apellidoPersona = this.contactForm.get("apellido").value;
      this.emailPersona = this.contactForm.get("email").value;
      this.telefonoPersona = this.contactForm.get("telefono").value;
      this.localidadPersona = this.contactForm.get("localidad").value;
      this.datoFormEdad1 = this.contactForm.get("edad1").value;
      this.datoFormEdad2 = this.contactForm.get("edad2").value;
      this.datoFormEdad3 = this.contactForm.get("edad3").value;
      this.datoFormEdad4 = this.contactForm.get("edad4").value;
      this.datoFormEdad5 = this.contactForm.get("edad5").value;
      this.datoFormEdad6 = this.contactForm.get("edad6").value;
      this.datoFormEdad7 = this.contactForm.get("edad7").value;
      this.datoFormEdad8 = this.contactForm.get("edad8").value;
      this.datoFormEdad9 = this.contactForm.get("edad9").value;
      this.datoFormEdad10 = this.contactForm.get("edad10").value;

      /*this.http.post('http://clientes.elpixel.net/vittal/wp-test/wp-json/contact-form-7/v1/contact-forms/' + formCode + '/feedback', formData).subscribe(
        (response) => console.log(response),
        (error) => console.log(error)
      )*/
    }
  }

  otraConsulta() {
    document.getElementById("contentAll").classList.remove("enviado");
    //location.reload();
    //return false;
  }

  /*APETURA DE MODAL */
  open(content) {
    //modal-dialog-centered
    this.modalService.open(content, {
      size: "xl",
      windowClass: "modal-cotizaciones",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
  }
  closeModal() {
    this.modalService.dismissAll();
    this.contactForm.reset();
  }
}
