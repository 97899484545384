import { Component, OnInit, Input } from '@angular/core';
import { DataWpServiceCategorias } from '../../data-wp.service';
import { PostI } from '../../post.interface';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-side-categorias',
  templateUrl: './side-categorias.component.html',
  styleUrls: ['./side-categorias.component.css'],
  providers: [DataWpServiceCategorias]
})
export class SideCategoriasComponent implements OnInit {
  @Input() slugCategoria: string;  
  
  posts: PostI[];
  constructor( private postsService: DataWpServiceCategorias, private router: Router) { }
  ngOnInit() {
    this.postsService.getPosts().subscribe( 
      (posts: PostI[]) => this.posts = posts, 
      (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));
  }
  selectPost(slug) {
  	this.router.navigate([slug]);
  }

}
