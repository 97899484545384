import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DataWpServiceThankYouPage } from 'src/app/data-wp.service';
import { PostI } from 'src/app/post.interface';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css'],
  providers: [DataWpServiceThankYouPage]
})
export class ThankyouComponent implements OnInit {

  constructor(private thankyouService: DataWpServiceThankYouPage) { }
  posts$: Observable<PostI[]>;
  html;
  ngOnInit() {
    this.posts$ = this.thankyouService.getPosts();
    this.posts$.subscribe(data=> this.html = data[0].content['rendered'])
  }

}
