import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL_JSON } from '../data-wp.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {


  form: FormGroup;
  viewSize;
  constructor(public fb: FormBuilder, private http: HttpClient) {
    this.form = this.fb.group({
      Nombre: [''],
      email: ['']
    })
  }

  ngOnInit() {
    this.viewSize = window.innerWidth;
  }


  submitForm() {
    var formData: any = new FormData();
    formData.append("Nombre", this.form.get('Nombre').value);
    formData.append("email", this.form.get('email').value);    
  
    this.http.post<any>(API_BASE_URL_JSON + '/contact-form-7/v1/contact-forms/779/feedback', formData).subscribe(
      res => {alert(res.message)}
    )
  }

}
