import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Observable } from "rxjs";
import {
  API_BASE_URL_JSON,
  DataWpServiceGestionOnline,
} from "src/app/data-wp.service";
import { SessionStorageService } from "src/app/services/session-storage.service";
import { GenericResponse } from "../../../generic-response.interface";

@Component({
  selector: "app-registro-socio",
  templateUrl: "./registro-socio.component.html",
  styleUrls: ["./registro-socio.component.css"],
  providers: [DataWpServiceGestionOnline],
})
export class RegistroSocioComponent implements OnInit {
  @ViewChild("modalEnvioOk", { static: false }) modalEnvioOk: ElementRef;
  @ViewChild("modalRespuesta", { static: false }) modalRespuesta: ElementRef;

  form: FormGroup;
  isHome;
  responseHttp: GenericResponse;
  screenSize;
  ischecked: boolean = false;
  dniOPass: string = "DNI";
  modalMessage: string;

  constructor(
    public formBuilder: FormBuilder,
    private modalService: NgbModal,
    private servicoGO: DataWpServiceGestionOnline,
    private ssService: SessionStorageService,
    private spinner: NgxUiLoaderService,
    private http: HttpClient
  ) {}

  get nombreSocioReg() {
    return this.contactForm.get("nombreSocioReg");
  }
  get apellidoSocioReg() {
    return this.contactForm.get("apellidoSocioReg");
  }
  get nroSocioReg() {
    return this.contactForm.get("nroSocioReg");
  }
  get emailSocioReg() {
    return this.contactForm.get("emailSocioReg");
  }
  get telefonoSocioReg2() {
    return this.contactForm.get("telefonoSocioReg2");
  }
  get telefonoSocioReg3() {
    return this.contactForm.get("telefonoSocioReg3");
  }
  get dniSocio() {
    return this.contactForm.get("dniSocio");
  }

  contactForm = this.formBuilder.group({
    nombreSocioReg: ["", [Validators.required]],
    apellidoSocioReg: ["", [Validators.required]],
    checkbox: [""],
    dniSocio: [
      "",
      [Validators.required, Validators.pattern("^(?:[0-9]{8})?$")],
    ],
    nroSocioReg: [
      "",
      [Validators.required, Validators.pattern("^[0-9a-zA-z/]{4,10}$")],
    ],
    emailSocioReg: [
      "",
      [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$"),
      ],
    ],
    telefonoSocioReg2: ["", [Validators.required]],
    telefonoSocioReg3: ["", [Validators.required]],
  });

  /*Funcion cuando se hace submit*/
  submitForm() {
    //alert('Registro Socio OK')
    let socio_nombre = this.contactForm.value.nombreSocioReg.toUpperCase();
    let socio_apellido = this.contactForm.value.apellidoSocioReg.toUpperCase();
    let socio_dni = this.contactForm.value.dniSocio;
    let socio_numero = this.contactForm.value.nroSocioReg;
    let socio_email = this.contactForm.value.emailSocioReg;
    let socio_area = this.contactForm.value.telefonoSocioReg2;
    let socio_telefono = this.contactForm.value.telefonoSocioReg3;

    let formData = new FormData();
    formData.append("numero_afiliado", this.contactForm.value.nroSocioReg);
    formData.append("nombre_afiliado", this.contactForm.value.nombreSocioReg);
    formData.append(
      "apellido_afiliado",
      this.contactForm.value.apellidoSocioReg
    );
    formData.append("email_afiliado", this.contactForm.value.emailSocioReg);

    this.spinner.start();
    this.servicoGO
      .register(
        socio_nombre,
        socio_apellido,
        socio_numero,
        socio_dni,
        socio_email,
        socio_area,
        socio_telefono
      )
      .subscribe((res) => {
        this.responseHttp = res;
        if (this.responseHttp.success) {
          formData.append("socio_guid", this.responseHttp.data.socio_guid);
          this.spinner.stop();
          this.openModalSendOk(this.modalEnvioOk);
          this.http
            .post<any>(
              API_BASE_URL_JSON +
                "/contact-form-7/v1/contact-forms/4167/feedback",
              formData
            )
            .subscribe();
          this.contactForm.reset();
        } else {
          this.spinner.stop();
          this.modalMessage = this.responseHttp.message.toLowerCase();
          this.openModal(this.modalRespuesta);
        }
      });
  }

  /*Funcion para abrir modal problemas de acceso*/
  openModalSendOk(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-problemas",
    });
  }

  openModal(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form",
    });
  }

  volverGestion() {
    this.modalService.dismissAll();
    document.getElementById("volverGestion").classList.remove("show");
    document.getElementById("loginSocios").classList.remove("show");
    document.getElementById("loginEmpresa").classList.remove("show");
    document.getElementById("contentRegistro").classList.remove("show");
    document.getElementById("registroSocios").classList.remove("show");
    document.getElementById("registroEmpresa").classList.remove("show");
    document.getElementById("loginFront").classList.add("show");
    document.getElementById("contentLogin").classList.add("show");
    if (this.screenSize < 760) {
      document.getElementById("titulosMobile").classList.add("show");
    }

    this.ssService.setHome();
  }

  ngOnInit() {
    this.screenSize = window.innerWidth;
    this.ssService.isHome().subscribe((e) => {
      this.isHome = e;
    });
  }

  ngAfterViewInit() {
    let dniForm = this.contactForm.get("dniSocio");
    let check: Observable<boolean> =
      this.contactForm.get("checkbox").valueChanges;
    check.subscribe((e) => {
      e ? (this.dniOPass = "Pasaporte") : (this.dniOPass = "DNI");
      this.ischecked = e;
      if (e) {
        dniForm.clearValidators();
        dniForm.setValidators([
          Validators.required,
          Validators.pattern("^([a-zA-Z]{3}[0-9]{6})$"),
        ]);
        dniForm.updateValueAndValidity();
      } else {
        dniForm.clearValidators();
        dniForm.setValidators([
          Validators.required,
          Validators.pattern("^(?:[0-9]{7,8})?$"),
        ]);
        dniForm.updateValueAndValidity();
      }
    });
  }
}
