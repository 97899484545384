import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataWpServiceFundacion } from '../data-wp.service';
import { Observable } from 'rxjs';
import { PostI } from '../post.interface';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-fundacion-vittal',
  templateUrl: './fundacion-vittal.component.html',
  styleUrls: ['./fundacion-vittal.component.css'],
  providers: [DataWpServiceFundacion],
  encapsulation: ViewEncapsulation.None
})
export class FundacionVittalComponent implements OnInit {
  product
  title
  constructor( 
    private postsService: DataWpServiceFundacion ,
    private titleService: Title, 
    private metaService: Meta
    ) {

     }
     
  posts$: Observable<PostI[]>;

  

  ngOnInit() {

    this.posts$ = this.postsService.getPosts();
    this.posts$.subscribe(data => { 
      /*Levantamos MetaTags*/
      var titleSeo = this.product=data[0].acf.seo.titulo;
      var descripcionSeo = this.product=data[0].acf.seo.descripcion;
      var keywordsSeo = this.product=data[0].acf.seo.keywords;
      /*Seteamos Metatags en header*/
      this.titleService.setTitle( 'Vittal - ' + titleSeo );
      this.metaService.updateTag( {name: 'keywords', content: keywordsSeo} );
      this.metaService.updateTag( {name: 'description', content: descripcionSeo} );
      this.metaService.updateTag( {name: 'robots', content: 'index, follow'} );
     } 
    )
  }

}
