import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { API_BASE_URL_JSON } from "src/app/data-wp.service";
import { emailData } from "src/app/shared/emailData";
import { SocioData } from "src/app/shared/socio-data.interface";

@Component({
  selector: "app-constancia-de-atencion",
  templateUrl: "./constancia-de-atencion.component.html",
  styleUrls: ["../../socios-gestion-online.component.css"],
})
export class ConstanciaDeAtencionComponent implements OnInit {
  form: FormGroup;
  fechaHoy;
  hoy;
  @Output() closeModal = new EventEmitter();
  @ViewChild("modalRespuesta", { static: false }) modalRespuesta: ElementRef;

  constructor(
    public formBuilder: FormBuilder,
    private http: HttpClient,
    private modalService: NgbModal,
    private datepipe: DatePipe
  ) {}

  get fecAtencion() {
    return this.contactForm.get("fecAtencion");
  }
  get telefono() {
    return this.contactForm.get("telefono");
  }

  contactForm = this.formBuilder.group({
    fecAtencion: ["", [Validators.required]],
    telefono: ["", [Validators.required, Validators.pattern("[0-9]+")]],
  });

  datosSocio: SocioData;
  modalMessage: string;

  ngOnInit() {
    this.datosSocio = JSON.parse(sessionStorage.getItem("data"));
    this.fechaHoy = Date.now();
    this.hoy = this.datepipe.transform(this.fechaHoy, "yyyy-MM-dd");
  }

  submitForm() {
    let formData = new FormData();
    let form = this.contactForm.value;
    formData.append("numero_afiliado", this.datosSocio.socio_numero);
    formData.append("nombre_afiliado", this.datosSocio.socio_nombre);
    formData.append("apellido_afiliado", this.datosSocio.socio_apellido);
    formData.append("email_afiliado", this.datosSocio.socio_email);
    formData.append("dominio_afiliado", this.datosSocio.socio_dominio);
    formData.append("fecha_atencion", form.fecAtencion);
    formData.append("telefono", form.telefono);

    this.datosSocio.socio_dominio.length > 0
      ? formData.append(
          "email_destino",
          emailData[this.datosSocio.socio_dominio]
        )
      : formData.append("email_destino", "cas@vittal.com.ar");

    this.http
      .post<any>(
        API_BASE_URL_JSON + "/contact-form-7/v1/contact-forms/3715/feedback",
        formData
      )
      .subscribe((res) => {
        this.closeModal.emit();
        this.modalMessage = res.message;
        this.openModal(this.modalRespuesta);
      });
  }

  openModal(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form",
    });
  }
}
