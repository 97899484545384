import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataWpServiceBlog, DataWpServiceTituloBlog } from '../data-wp.service';
import { PostI } from '../post.interface';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css'],
  providers: [DataWpServiceBlog, DataWpServiceTituloBlog],
  encapsulation: ViewEncapsulation.None

})
export class BlogComponent implements OnInit {
  product
  title
  postsTitulos: PostI[]; 
  posts$: Observable<PostI[]>;
  posts: PostI[];

  /*AJUSTES PARA PAGINATOR*/
  config = {
    itemsPerPage: 5,
    currentPage: 1,
    totalItems: this.posts
  };

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  public maxSize: number = 5;
  public directionLinks: boolean = true;
  public autoHide: boolean = false;
  public responsive: boolean = true;
  public labels: any = {
      previousLabel: '',
      nextLabel: '',
      screenReaderPaginationLabel: 'Pagination',
      screenReaderPageLabel: 'page',
      screenReaderCurrentLabel: `You're on page`      
  };
  
  onPageChange(event){
    console.log(event);
    this.config.currentPage = event;
  }

  constructor( 
    private postsServiceTitulos: DataWpServiceTituloBlog, 
    private postsService: DataWpServiceBlog, 
    private router: Router ,
    private titleService: Title, 
    private metaService: Meta ) {
    
  }

  /*CONFIGURACION DE POSTEOS DE WP*/
  ngOnInit() {

    this.posts$ = this.postsServiceTitulos.getPosts();
    this.posts$.subscribe(data => { 
      /*Levantamos MetaTags*/
      var titleSeo = this.product=data[0].acf.seo.titulo;
      var descripcionSeo = this.product=data[0].acf.seo.descripcion;
      var keywordsSeo = this.product=data[0].acf.seo.keywords;
      /*Seteamos Metatags en header*/
      this.titleService.setTitle( 'Vittal - ' + titleSeo );
      
      this.metaService.updateTag( {name: 'keywords', content: keywordsSeo} );
      this.metaService.updateTag( {name: 'description', content: descripcionSeo} );
      this.metaService.updateTag( {name: 'robots', content: 'index, follow'} );
     } 
    )

    this.postsService.getPosts().subscribe( 
      (posts: PostI[]) => {this.posts = posts
      console.log(posts);
      
      }, 
      (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));

      this.postsServiceTitulos.getPosts().subscribe( 
        (postsTitulos: PostI[]) => this.postsTitulos = postsTitulos, 
        (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));  

  }
  selectPost(slug) {
  	this.router.navigate([slug]);
  }



}
