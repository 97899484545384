import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { DataWpServiceCartillaMedica, API_BASE_URL_V } from '../../data-wp.service';
import { PostI } from '../../post.interface';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject  } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { MatExpansionPanel } from '@angular/material';
import { DatosResultados } from './datos-resultados.interface';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AgmMap, MapTypeId } from '@agm/core';
import { map } from 'jquery';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';

@Component({
  selector: 'app-cartilla-medica',
  templateUrl: './cartilla-medica.component.html',
  styleUrls: ['./cartilla-medica.component.css'],
  providers: [DataWpServiceCartillaMedica],
  encapsulation: ViewEncapsulation.None
})


export class CartillaMedicaComponent implements OnInit, AfterViewInit {

@ViewChild('sectionResultados', {static: false}) sectionResultados: ElementRef;
@ViewChild('sectionSinresultados', {static: false}) sectionSinresultados: ElementRef;


  product
  title
  posts$: Observable<PostI[]>;
  posts: PostI[];

  provincias: [];
  localidades: [];
  rubros: [];
  especialidades: [];
  practicas: [];
  usr_lat;
  usr_lng;
  map;
  isGeoEnable: boolean;
  isGeoMap: boolean;
  isMobile: boolean;
  resultados: [{
    atencion: [{dias:[], horario:''}],
    direccion: '',
    email: '',
    id: '',
    latitud: '',
    longitud: '',
    prestador: [{nombre:''}],
    rubros?: [{term_id:'',name:'', especialidades: [{term_id:'',name:'', practicas: [{term_id:'',name:''}]}] }],
    telefono: [{numero:'',es_whatsap:''}],
    title: '',
    web: '',
    web_turnos: '',
    whatsapp:[{numero:'', es_whatsap:''}]
  }];
  
  campo_rubro : '';
  createFormGroup(){
    return new FormGroup({
        provincia: new FormControl(''),
        localidad: new FormControl({value:'', disabled:true}),
        rubro: new FormControl(''),
        especialidad: new FormControl({value:'', disabled:true}),
        practica: new FormControl({value:'', disabled:true}),
        entidad: new FormControl('')
    });
  };


 busquedaGeo =  new FormGroup({
    direccion: new FormControl(''),
    rubro: new FormControl(''),
    especialidad: new FormControl({value:'', disabled:true}),
    practica: new FormControl({value:'', disabled:true}),
    entidad: new FormControl('')
  });


  busquedaEsp: FormGroup; 
  breakpoint: number;
  destroyed = new Subject<void>();
  currentScreenSize: string;
  itemsPerPage: number = 5;
  currentPage: number;
 
  displayNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Large, 'Large'],
    [Breakpoints.XLarge, 'XLarge'],
  ]);

datosResultados: DatosResultados;
datosMarker: DatosResultados;
paramId;
isPress: boolean;
valueSelect;
hayResultado: boolean;

  constructor( 
    private postsService: DataWpServiceCartillaMedica, 
    private router: Router,
    private titleService: Title, 
    private metaService: Meta,
    private breakpointObserver: BreakpointObserver, private render: Renderer2) { 
      breakpointObserver.observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ]).pipe(takeUntil(this.destroyed)).subscribe(result => {
          for (const query of Object.keys(result.breakpoints)) {
            if (result.breakpoints[query]) {
              this.currentScreenSize = this.displayNameMap.get(query);              
            }
          }
      });
      this.hayResultado = false;
    }

  ngOnInit() {
    this.postsService.getPosts().subscribe( 
      (posts: PostI[]) => this.posts = posts, 
      (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));
      
      this.posts$ = this.postsService.getPosts();
      this.posts$.subscribe(data => { 
        /*Levantamos MetaTags*/
        var titleSeo = this.product=data[0].acf.seo.titulo;
        var descripcionSeo = this.product=data[0].acf.seo.descripcion;
        var keywordsSeo = this.product=data[0].acf.seo.keywords;
        /*Seteamos Metatags en header*/
        this.titleService.setTitle( 'Vittal - ' + titleSeo );
        this.metaService.updateTag( {name: 'description', content: descripcionSeo} );
        this.metaService.updateTag( {name: 'keywords', content: keywordsSeo} );
        this.metaService.updateTag( {name: 'robots', content: 'index, follow'} );
       } 
      );

      this.postsService.getRubros( API_BASE_URL_V + '/rubros/').subscribe((res:any) => {
        this.rubros = res; 
      });
      
      this.postsService.getProvincias( API_BASE_URL_V + '/provincias/').subscribe((res:any) => {
        this.provincias = res;
      });

      this.busquedaEsp = this.createFormGroup();

      navigator.geolocation.getCurrentPosition(res =>{
        this.usr_lat = res.coords.latitude;
        this.usr_lng = res.coords.longitude;
        this.isGeoEnable = true;
        document.getElementById('tituloEspecialidad').classList.remove('active');
        document.getElementById('esp').classList.remove('active', 'show');
        document.getElementById('tituloGeolocalizacion').classList.add('active');
        document.getElementById('geo').classList.add('active', 'show');    
      }, err=>{
        this.isGeoEnable = false;

      });
   
      
      this.breakpoint = (window.innerWidth <= 800) ? 1 : 2;
      this.isMobile = window.innerWidth <= 500 ? true : false;
  }

  ngAfterViewInit(){
    this.render.addClass(this.sectionSinresultados.nativeElement, 'none');
  }
  
  //formulario
  onResetForm(){
    this.busquedaEsp.reset();
  }

  onSaveForm(){
    console.log('save');
  }

  resetForm(){
    this.busquedaEsp.value.provincia = '';
    this.busquedaEsp.value.localidad = ''; 
    this.busquedaEsp.value.rubro = '';
    this.busquedaEsp.value.especialidad = '';
    this.busquedaEsp.value.practica = '';
    this.busquedaEsp.value.entidad = '';
    this.busquedaGeo.value.rubro = '';
    this.busquedaGeo.value.especialidad = '';
    this.busquedaGeo.value.practica = '';
    this.busquedaGeo.value.entidad = '';
    this.resetEspecialidad();
    this.resetLocalidades();
    this.resetPracticas();
    this.resetRubros();
    this.resetProvincias();
    this.resetResultados();    
  }

  resetProvincias(){    
    this.busquedaEsp.controls['provincia'].setValue('');
  }

  resetDireccion(){
    this.busquedaGeo.controls['direccion'].setValue('');
  }

  resetRubros(){    
    this.busquedaGeo.controls['rubro'].setValue('');
    this.busquedaEsp.controls['rubro'].setValue('');
  }
  resetEspecialidad(){
    this.especialidades = [];
    this.busquedaEsp.controls['especialidad'].setValue('');
    this.busquedaEsp.controls['especialidad'].disable();
    this.busquedaGeo.controls['especialidad'].setValue('');
    this.busquedaGeo.controls['especialidad'].disable();
  }
  resetPracticas(){
    this.practicas = [];
    this.busquedaEsp.controls['practica'].setValue('');
    this.busquedaEsp.controls['practica'].disable();
    this.busquedaGeo.controls['practica'].setValue('');
    this.busquedaGeo.controls['practica'].disable();
  }
  resetResultados(){
    this.resultados = [{
      atencion: [{dias:[], horario:''}],
      direccion: '',
      email: '',
      id: '',
      latitud: '',
      longitud: '',
      prestador: [{nombre:''}],
      rubros: [{term_id:'',name:'', especialidades: [{term_id:'',name:'', practicas: [{term_id:'',name:''}]}] }],
      telefono: [{numero:'',es_whatsap:''}],
      title: '',
      web: '',
      web_turnos: '',
      whatsapp:[{numero:'', es_whatsap:''}]
    }];
  }

  resetLocalidades(){
    this.localidades = [];
    this.busquedaEsp.controls['localidad'].setValue('');
    this.busquedaEsp.controls['localidad'].disable();
  }

  onChangeProvincia(value){
    this.resetLocalidades();
    if(value !== ''){
      this.postsService.getLocalidades( API_BASE_URL_V + '/localidades/',value).subscribe((res:any) => {
        if(res){
          var length = Object.keys(res).length;
          if(length > 0){
            this.localidades = res; 
            this.busquedaEsp.controls['localidad'].enable();
          }
        }
      });
    }
  }

  onChangeRubro(value){
    this.resetEspecialidad();
    this.resetPracticas();    
    if(value !== ''){
      this.postsService.getEspecialidades( API_BASE_URL_V + '/especialidades/',value).subscribe((res:any) => {
        if(res){
          var length = Object.keys(res).length;
          if(length > 0){
            this.especialidades = res;
            this.busquedaEsp.controls['especialidad'].enable();
            this.busquedaGeo.controls['especialidad'].enable();
          }
        }
      });
    }
  }

  onChangeEspecialidad(value){
    this.resetPracticas();
    if(value !== ''){
      this.postsService.getEspecialidades( API_BASE_URL_V + '/especialidades/',value).subscribe((res:any) => {
        if(res){
          var length = Object.keys(res).length;
          if(length > 0){
            this.practicas = res;
            this.busquedaEsp.controls['practica'].enable();
            this.busquedaGeo.controls['practica'].enable();
          }
        }
      });
    }
  }


  selectPost(slug) {
  	this.router.navigate([slug]);
  }

  /* FUNCION PARA MOSTRAR FILTROS POR GEOLOCALIZACION */
  addClassGeo(){
    document.getElementById("selector").classList.add("geo");    
  }

  /* FUNCION PARA MOSTRAR OCULTAR FILTROS GEOLOCALIZACION */
  removeClassGeo(){
    document.getElementById("selector").classList.remove("geo");
  }

  /* FUNCION PARA MOSTRAR LOS RESOLUTADOS DE LA BUSQUEDA */
  buscarCartilla() {
    var provincia = (this.busquedaEsp.value.provincia === undefined)? '' : this.busquedaEsp.value.provincia;
    var localidad = (this.busquedaEsp.value.localidad === undefined)? '' : this.busquedaEsp.value.localidad;
    var rubro = (this.busquedaEsp.value.rubro === undefined)? '' : this.busquedaEsp.value.rubro;
    var especialidad = (this.busquedaEsp.value.especialidad === undefined)? '' : this.busquedaEsp.value.especialidad;
    var practica = (this.busquedaEsp.value.practica === undefined)? '' : this.busquedaEsp.value.practica;
    var entidad = (this.busquedaEsp.value.entidad === undefined)? '' : this.busquedaEsp.value.entidad;
    var direccionGeo = (this.busquedaGeo.value.direccion === undefined) ? '' : this.busquedaGeo.value.direccion;
    var rubroGeo = (this.busquedaGeo.value.rubro === undefined)? '' : this.busquedaGeo.value.rubro;
    var especialidadGeo = (this.busquedaGeo.value.especialidad === undefined)? '' : this.busquedaGeo.value.especialidad;
    var practicaGeo = (this.busquedaGeo.value.practica === undefined)? '' : this.busquedaGeo.value.practica;
    var entidadGeo = (this.busquedaGeo.value.entidad === undefined)? '' : this.busquedaGeo.value.entidad;
    var usr_lat = (this.usr_lat === undefined)? '' : this.usr_lat;
    var usr_lng = (this.usr_lng === undefined)? '' : this.usr_lng;
    if(document.getElementById('tituloGeolocalizacion').classList.contains('active')){
      this.postsService.getSucursales( API_BASE_URL_V + '/sucursales/', provincia, localidad, rubroGeo, especialidadGeo, practicaGeo, entidadGeo, usr_lat, usr_lng).subscribe((res:any) => {
        this.resetResultados();
        if(res){
          var length = Object.keys(res).length;
          if(length > 0){
            console.log('res sucursales');
            console.log(res);

            this.resultados = res;
            this.isGeoMap = true;            
            this.hayResultado = true;
            this.lat = res[0]['latitud'];
            this.lng = res[0]['longitud'];            
            document.getElementById("resultados").classList.add("on");
            document.getElementById("singleResultado").classList.add("none");
            document.getElementById("markerInfo").classList.add("none"); 
            document.getElementById("mapaSlingle").classList.add("none");
            this.resetState();
            //this.verMapaList();
            this.valueSelect == 'botonVerLista' ? this.verLista(): this.verMapaList();
            //document.getElementById("btnLista").classList.add("active");    
            
          }else{
            this.hayResultado = false;
            //document.getElementById("resultados").classList.remove("on");
            this.render.removeClass(this.sectionSinresultados.nativeElement, 'none'); 
            //document.getElementById("btnLista").classList.remove("active");  
          }
        }
      });
    } else {
      this.postsService.getSucursales( API_BASE_URL_V + '/sucursales/', provincia, localidad, rubro, especialidad, practica, entidad).subscribe((res:any) => {
        this.resetResultados();
        if(res){
          var length = Object.keys(res).length;
          if(length > 0){
            console.log('res sucursales');
            console.log(res);

            this.resultados = res;
            this.hayResultado = true;
            this.lat = res[0]['latitud'];
            this.lng = res[0]['longitud'];

            this.isGeoMap = false;
            document.getElementById("resultados").classList.add("on");
            document.getElementById("singleResultado").classList.add("none");
            document.getElementById("markerInfo").classList.add("none"); 
            document.getElementById("mapaSlingle").classList.add("none");
            this.resetState();
            this.valueSelect == 'botonVerMapa' ? this.verMapaList() : this.verLista();            
          }else{
            this.hayResultado = false;
            this.render.removeClass(this.sectionSinresultados.nativeElement, 'none');
             //document.getElementById("resultados").classList.add("on");
             document.getElementById("singleResultado").classList.add("none");
             document.getElementById("markerInfo").classList.add("none"); 
             document.getElementById("mapaSlingle").classList.add("none");
   
          }
        }
      });
    }
    document.getElementById("anchorResultados").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
      })
  }
  
  /* FUNCION PARA MOSTRAR LOS RESOLUTADOS DE LA BUSQUEDA EN LISTA */
  verLista(){
    document.getElementById("itemsResults").classList.remove("none");
    document.getElementById("mapaSlingle").classList.add("none");
    document.getElementById("mapaList").classList.add("none");
    document.getElementById("singleResultado").classList.add("none");
    this.valueSelect = 'botonVerLista'
  
  }
  public getMapInstance(map: any): void { this.map = map; }
  /* FUNCION PARA MOSTRAR LOS RESOLUTADOS DE LA BUSQUEDA EN MAPA */
  verMapaList(){
    document.getElementById("mapaList").classList.remove("none");
    // Refresco para ajustar limites del Mapa
    if(this.hayResultado){
      let datosTemp = [...this.resultados];
      this.resetResultados();
      datosTemp.forEach(e=>{
        this.resultados.push(e);
      })
      setTimeout(()=>{
        this.resultados.shift();
      },10);       
    }
    //////////////////////////////////////////
    document.getElementById("itemsResults").classList.add("none");
    document.getElementById("mapaSlingle").classList.add("none");
    document.getElementById("mapaList").classList.remove("none");
    document.getElementById("singleResultado").classList.add("none");
    document.getElementById("markerInfo").classList.add("none");    
    this.valueSelect = 'botonVerMapa';     
  }

  resetState(){    
    this.paramId = ''
  }

  verPractica(datos){
    let condicion;
    datos.forEach( element => { 
      element.especialidades.forEach( element => { element.practicas.forEach(element => {
       condicion = element ? true : false;        
      });        
      });
    });
    return condicion;
  }

  verEspecialidad(datos){
    let condicion;
    datos.forEach(element => {
      element.especialidades.forEach(element => {
        condicion = element ? true : false;
      });
      
    });
    return condicion;
  }

  /* FUNCION PARA MOTRAR UN RESULTADO EN PARTICULAR */
  verSingle(){
    document.getElementById("itemsResultados").classList.add("none");
    document.getElementById("mapaSlingle").classList.remove("none");
    document.getElementById("mapaList").classList.add("none");
    document.getElementById("btnLista").classList.remove("active");    
    document.getElementById("btnMapa").classList.remove("active");
  }

  /*Variables Form*/



  /* VARIABLES GOOLE MAPS */
  /* GENERALES */

  lat: number = -34.564198;
  lng: number = -58.4479537;
  latSingle: number = -34.564198;
  lngSingle: number = -58.4479537;

  zoom: number = 14;
  zoomSingle: number = 14;
  scrollwheel: false;
  styles: any[] =  [{"featureType":"administrative.locality","elementType":"all","stylers":[{"hue":"#2c2e33"},{"saturation":7},{"lightness":19},{"visibility":"on"}]},{"featureType":"landscape","elementType":"all","stylers":[{"hue":"#ffffff"},{"saturation":-100},{"lightness":100},{"visibility":"simplified"}]},{"featureType":"poi","elementType":"all","stylers":[{"hue":"#ffffff"},{"saturation":-100},{"lightness":100},{"visibility":"off"}]},{"featureType":"road","elementType":"geometry","stylers":[{"hue":"#bbc0c4"},{"saturation":-93},{"lightness":31},{"visibility":"simplified"}]},{"featureType":"road","elementType":"labels","stylers":[{"hue":"#bbc0c4"},{"saturation":-93},{"lightness":31},{"visibility":"on"}]},{"featureType":"road.arterial","elementType":"labels","stylers":[{"hue":"#bbc0c4"},{"saturation":-93},{"lightness":-2},{"visibility":"simplified"}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"hue":"#e9ebed"},{"saturation":-90},{"lightness":-8},{"visibility":"simplified"}]},{"featureType":"transit","elementType":"all","stylers":[{"hue":"#e9ebed"},{"saturation":10},{"lightness":69},{"visibility":"on"}]},{"featureType":"water","elementType":"all","stylers":[{"hue":"#e9ebed"},{"saturation":-78},{"lightness":67},{"visibility":"simplified"}]}];
  
    
  /* FUNCION PARA TOMAR DATOS DESDE BOX DE RESULTADOS E IMPRIMIRLOS EN BOX INTERIOR DE MAPA */
  verSingleResults( resultado: DatosResultados ){

   this.datosResultados = {
     title: resultado.title,
     rubros: resultado.rubros,
     telefono: resultado.telefono,
     atencion: resultado.atencion,
     direccion: resultado.direccion,
     latitud: resultado.latitud,
     longitud: resultado.longitud,
     whatsapp: resultado.whatsapp,
     email: resultado.email,
     id: resultado.id
   }
   
    document.getElementById("mapaSlingle").classList.remove("none");
    document.getElementById("mapaList").classList.add("none");
    document.getElementById("itemsResults").classList.add("none");
    document.getElementById("singleResultado").classList.add("none");
 
    
    this.latSingle = Number(resultado.latitud);
    this.lngSingle = Number(resultado.longitud);
    this.zoomSingle = 16;
    this.valueSelect = null;
   

  }   

  verInfoMarker(data: DatosResultados){    
    this.datosMarker = data;
    document.getElementById("markerInfo").classList.remove("none");
    document.getElementById("singleResultado").classList.add("none");    
    this.valueSelect = null;
    
  }

  verSingleInfo(data: DatosResultados){
    this.paramId = data.id;
    this.isPress = this.isPress ? this.isPress : !this.isPress ;    
    this.valueSelect = null;
    
      document.getElementById("mapaSlingle").classList.add("none");
      document.getElementById("itemsResults").classList.add("none");
      document.getElementById("markerInfo").classList.add("none");
      document.getElementById("singleResultado").classList.remove("none");    
    
  }

  verSingleDataList(data){    
    this.paramId = data;
    this.valueSelect = 'botonVerLista';
    document.getElementById(data).scrollIntoView();
    document.getElementById("mapaSlingle").classList.add("none");
    document.getElementById("mapaList").classList.add("none");
    document.getElementById("itemsResults").classList.remove("none");
    document.getElementById("singleResultado").classList.add("none");
  }

//  FUNCIONES PARA LIMPIAR LAS PESTAÑAS DEL BOX DE BUSQUEDA

  isEsp(){
    this.isGeoMap = false;
    this.resetForm();
    this.valueSelect = 'botonVerLista';
    this.render.addClass(this.sectionSinresultados.nativeElement, 'none');
    if(this.hayResultado){
      this.render.removeClass(this.sectionResultados.nativeElement, 'on');
      this.render.addClass(this.sectionResultados.nativeElement, 'none');
      this.resetResultados();
      this.currentPage = 1;    
    }
  }

  isGeo(){
    this.isGeoMap = true;  
    this.resetForm();
    this.valueSelect = 'botonVerMapa';
    this.render.addClass(this.sectionSinresultados.nativeElement, 'none');
    if(this.hayResultado){
      this.render.removeClass(this.sectionResultados.nativeElement, 'on');
      this.render.addClass(this.sectionResultados.nativeElement, 'none');
      this.resetResultados();
      this.currentPage = 1;    
    }
  }


  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 800) ? 1 : 2;
  }

  onChange(event){        
    event.value == 'botonVerLista' ? this.verLista() : this.verMapaList();
  }


  expandPanel(matExpansionPanel: MatExpansionPanel, event: any) {
    event.stopPropagation();    
    if (!this._isExpansionIndicator(event.target)) {
      matExpansionPanel.close();
    }
  }

  private _isExpansionIndicator(target: EventTarget | any): boolean {
    const expansionIndicatorClass = "mat-expansion-indicator";
    const labelArrow = "labelArrow";    
    return (
      (target.classList && target.classList.contains(expansionIndicatorClass) || target.classList && target.classList.contains(labelArrow))
    );
  }

  ngOnDestroy(){
    this.destroyed.next();
    this.destroyed.complete();    
  }

}

export interface mensaje{
    provincia: string;
    localidad: string;
    rubro: string;
    especialidad: string;
    practica: string;
    entidad: string;
}
