import { Component, OnInit} from '@angular/core';
import { DataWpServiceBannerCotizadores } from '../../data-wp.service';
import { PostI } from '../../post.interface';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css'],
  providers: [DataWpServiceBannerCotizadores]
})
export class BannerComponent implements OnInit {

  posts: PostI[];

  constructor(private postsService: DataWpServiceBannerCotizadores) { }

  /*CONFIGURACION DE POSTEOS DE WP*/
  ngOnInit() {

    this.postsService.getPosts().subscribe( 
      (posts: PostI[]) => this.posts = posts, 
      (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));
  }

}
