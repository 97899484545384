import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-idoc",
  templateUrl: "./idoc.component.html",
  styleUrls: ["./idoc.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class IdocComponent implements OnInit {
  @Input() headerImange;
  @Input() headerLogo;
  @Input() headerBajada;
  @Input() headerIntroduccion;
  @Input() headerImgBanner;

  @Input() Bullets1Icono;
  @Input() Bullets1Titulo;
  @Input() Bullets1Bajada;
  @Input() Bullets2Icono;
  @Input() Bullets2Titulo;
  @Input() Bullets2Bajada;
  @Input() Bullets3Icono;
  @Input() Bullets3Titulo;
  @Input() Bullets3Bajada;
  @Input() BulletsImg;

  @Input() DescargaTitulo;
  @Input() DescargaSubTitulo;
  @Input() DescargaTitulo_box_1;
  @Input() DescargaLink_1_box_1;
  @Input() DescargaLink_2_box_1;
  @Input() DescargaTitulo_box_2;
  @Input() DescargaLink_box_2;

  @Input() pasosPaso_1Imagen;
  @Input() pasosPaso_1Bajada;
  @Input() pasosPaso_2Imagen;
  @Input() pasosPaso_2Bajada;
  @Input() pasosPaso_3Imagen;
  @Input() pasosPaso_3Bajada;
  @Input() pasosTextoFinal;
  @Input() legales;

  constructor(
    private sanitizer: DomSanitizer,
    private modalService: NgbModal
  ) {}

  ngOnInit() {}

  openModal(content) {
    this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      size: "lg",
    });
  }
  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
