import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AuthService } from "src/app/services/auth.service";
import { EmpresaData } from "src/app/shared/empresa-data.interface";

@Component({
  selector: "app-empresa-gestion-online",
  templateUrl: "./empresa-gestion-online.component.html",
  styleUrls: ["./empresa-gestion-online.component.css"],
})
export class EmpresaGestionOnlineComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    private authSrv: AuthService,
    private spinner: NgxUiLoaderService
  ) {}

  datosEmpresa: EmpresaData;

  ngOnInit() {
    this.datosEmpresa = this.authSrv.getDataLogin();
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.spinner.stop();
  }
  /*Funcion para abrir modal problemas de acceso*/
  openModalForms(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form",
    });
  }
  /*Funcion para abrir modal problemas de acceso*/
  openModalFormsLg(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form-lg",
    });
  }

  /*Funcion para cerrar el modal */

  closeModal() {
    this.modalService.dismissAll();
  }

  logout() {
    this.authSrv.logout();
  }
}
