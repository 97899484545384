import { Component, OnInit, Pipe, PipeTransform  } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { DataWpServiceSlider } from '../../data-wp.service';
import { PostI } from '../../post.interface';


@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css'],
  providers: [DataWpServiceSlider]
})


export class SliderComponent implements OnInit {

  constructor(
    private dataWp: DataWpServiceSlider, 
    private sanitizer: DomSanitizer
    ) { 

    }
  
  posts$: Observable<PostI[]>;
  
  transform(html) {
      return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnInit() {
    this.posts$ = this.dataWp.getPosts();
  }  

}
