import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { PostI } from '../../../post.interface';
import { DataWpServiceListadoPostFiltroCategoria } from '../../../data-wp.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['../../blog.component.css'],
  providers: [DataWpServiceListadoPostFiltroCategoria],
  encapsulation: ViewEncapsulation.None
})
export class ListComponent implements OnInit {

  @Input() slugCategoria: string;  
  posts: PostI[];/* POSTS DENTRO DE LA CATEGORIA */

  /*AJUSTES PARA PAGINATOR*/
  config = {
    itemsPerPage: 5,
    currentPage: 1,
    totalItems: this.posts
  };

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  public maxSize: number = 5;
  public directionLinks: boolean = true;
  public autoHide: boolean = true;
  public responsive: boolean = true;
  public labels: any = {
      previousLabel: '',
      nextLabel: '',
      screenReaderPaginationLabel: 'Pagination',
      screenReaderPageLabel: 'page',
      screenReaderCurrentLabel: `You're on page`      
  };
  
  onPageChange(event){
    console.log(event);
    this.config.currentPage = event;
  }
  

  constructor( private postsServicePosteos: DataWpServiceListadoPostFiltroCategoria ) { }

  ngOnInit() {
    console.log(this.slugCategoria);

    /*LEVANTA POSTEOS DE ESA CATEGORIA*/
    this.postsServicePosteos.getPosts(this.slugCategoria).subscribe( 
      (posts: PostI[]) => this.posts = posts, 
      (err: HttpErrorResponse) => err.error instanceof Error ? console.log('An error occurred:', err.error.message) : console.log(`Backend returned code ${err.status}, body was: ${err.error}`));

  }

}
