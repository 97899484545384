import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { BehaviorSubject, Subject } from "rxjs";
import { DataWpServiceGestionOnline } from "../data-wp.service";
import { GenericResponse } from "../generic-response.interface";
import { AuthData } from "../shared/auth-data.interface";
import { SocioData } from "../shared/socio-data.interface";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private authStatusListener = new BehaviorSubject<boolean>(false);
  private isAuthenticated: boolean;
  private datosSocio: SocioData;
  public logeado;

  constructor(
    private http: HttpClient,
    private router: Router,
    private serviceGo: DataWpServiceGestionOnline,
    private spinner: NgxUiLoaderService
  ) {}

  getIsAuth(): boolean {
    console.log(this.isAuthenticated);

    return this.isAuthenticated;
  }

  getAuthStatusListener() {
    return this.authStatusListener;
  }

  getDataLogin() {
    return JSON.parse(sessionStorage.getItem("data"));
  }

  save(res) {
    this.datosSocio = res.data;
    this.saveAuthData(this.datosSocio);
    this.isAuthenticated = true;
    this.authStatusListener.next(true);
  }

  loginEmpresa(socio_dni: number, socio_email: string) {
    const authData: AuthData = {
      socio_dni: socio_dni,
      socio_email: socio_email,
    };

    this.serviceGo
      .login(authData.socio_dni, authData.socio_email)
      .subscribe((res) => {
        if (res.success) {
          this.datosSocio = res.data;
          this.saveAuthData(this.datosSocio);
          this.isAuthenticated = true;
          this.authStatusListener.next(true);
          this.router.navigate(["/gestion-online/empresas"]);
        } else {
          alert("Error:" + res.message);
        }
      });
  }

  private saveAuthData(data) {
    sessionStorage.setItem("data", JSON.stringify(data));
    sessionStorage.setItem("isLogged", "true");
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(["atencion-al-socio/gestion-online"]);
  }
}
