import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css']
})
export class ServiciosComponent implements OnInit {

  slides2 = [
    {img: "assets/img/servicios-pic-01.jpg", titulo: 'Servicio de espacio cardioprotegido', bajada: 'Este servicio tiene el adicional de la instalación de un <strong>DEA</strong> (Desfibrilador Externo Automático) y cursos de <strong>RCP</strong> (Reanimación Cardiopulmonar) en sus instalaciones, lo cual mantiene a su personal listo para casos de emergencia.'},
    {img: "assets/img/servicios-pic-02.jpg", titulo: 'Servicio diferencial para instituciones educativas ', bajada: 'Cobertura ante emergencias médicas, urgencias y médicos a domicilio. Extensión de área protegida a campos de deporte, excursiones o viajes de estudio.'},
    {img: "assets/img/servicios-pic-03.jpg", titulo: 'Convenio capitado ', bajada: 'Con los convenios capitados usted puede proteger a sus empleados estén donde estén y también extender la asistencia de vittal a los grupos familiares de sus empleados.'},
    {img: "assets/img/servicios-pic-04.jpg", titulo: 'Traslados programados', bajada: 'Con ambulancias y móviles especialmente equipados, y paramédicos dedicados exclusivamente a atender tus necesidades, le garantizamos el mejor servicio para su tranquilidad y la de su empresa.'},
    {img: "assets/img/servicios-pic-05.jpg", titulo: 'Cobertura de eventos ', bajada: 'Servicio destinado a cubrir eventos o espectáculos que requieren de logística médica, previniendo cualquier emergencia. Dependiendo de la magnitud y la cantidad de personas, puede disponer de ambulancias y médicos especializados para cubrir el evento desde el armado y hasta su finalización.'},
    {img: "assets/img/servicios-pic-06.jpg", titulo: 'Vittal dron ', bajada: 'Es un vehículo aéreo pilotado, pionero en Argentina, que traslada un Des­brilador Externo Automático (DEA) para asistir a personas en situación de pérdida de conocimiento súbita. Su presencia en eventos masivos al aire libre (maratones, encuentros deportivos, recitales, etc.) es fundamental para proporcionar una asistencia oportuna ante este tipo de emergencias médicas.'}
  ];

  constructor() { }

  ngOnInit() {
  }

}
