import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { SocioData } from 'src/app/shared/socio-data.interface';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: "app-credencial",
  templateUrl: "./credencial.component.html",
  styleUrls: ["./credencial.component.css"],
})
export class CredencialComponent implements OnInit {
  constructor(private authSrv: AuthService) {}
  datosSocio: SocioData;

  ngOnInit() {
    this.datosSocio = JSON.parse(sessionStorage.getItem("data"));
  }

  logout() {
    this.authSrv.logout();
  }

  // printPdf() {
  //   //let credencial = document.getElementById("containerCard").innerHTML;
  //   let ventana = window.open("", "popup");
  //   ventana.document.write("<html><head></head><body>");
  //   ventana.document.write(
  //     `<style>
  //     @media print {.containerCard {width: 480;
  //      height: 275;
  //      display: flex;}
  //    .txt{
  //      position: absolute;
  //      top: 170px;
  //      left: 290px;
  //      text-align: right;
  //    } }</style>
  //     <div class="containerCard" style="
  //     width: 430;
  //     height: 275;
  //     display: flex;
  // "><img src="/assets/img/credencial-${this.datosSocio.socio_dominio}.png" alt=""><div class="txt" ><h3 style="font-weight: bold;
  // color: #fff;
  // font-size: 16px;
  // font-family: 'Open Sans', sans-serif;">${this.datosSocio.socio_nombre}  ${this.datosSocio.socio_apellido}</h3><p style="
  // color: #fff;
  // font-size: 16px;
  // font-family: 'Open Sans', sans-serif; margin: 0px;">Nº de socio </p> <p style="font-weight: bold;
  // color: #fff;
  // font-size: 18px;
  // font-family: 'Open Sans', sans-serif; margin-top: 8px;"> <strong >${this.datosSocio.socio_numero}</strong></p></div>`
  //   );
  //   ventana.document.write("</div></body></html>");
  //   ventana.document.close();
  //   setTimeout(() => {
  //     ventana.print();
  //     ventana.close();
  //   }, 200);
  // }

  printPdf(){
    const data = document.getElementById("credencial");  
    html2canvas(data).then((canvas) => {
      let fileWidth = 113;
      let fileHeight = 72;  
      const FILEURI = canvas.toDataURL('image/png');    
      let PDF = new jsPDF('p', 'mm', 'a4');
      let positionY = 30;
      let positionX= 50; 
      PDF.addImage(FILEURI, 'PNG', positionX, positionY, fileWidth, fileHeight);     
      PDF.save('credencial.pdf');
    });
  }
}
