import { Component, OnInit,ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-espacio-cardioprotegido',
  templateUrl: './espacio-cardioprotegido.component.html',
  styleUrls: ['./espacio-cardioprotegido.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EspacioCardioprotegidoComponent implements OnInit {

  slides = [
    {img: "assets/img/medevac-slide-01.jpg", titulo: 'Asistencia las 24 horas:', bajada: 'Cuando se trata de una emergencia médica, el tiempo de traslado es fundamental, por eso, nuestros Servicios Aeromédicos garantizan respuesta, disponibilidad y rapidez para casos de urgencia médica o derivación las 24 horas.'},
    {img: "assets/img/medevac-slide-02.jpg", titulo: 'Asistencia Integral:', bajada: 'Además de proporcionar el servicio aeromédico, nos ocupamos también del servicio completo en Argentina y en países limítrofes.'},
    {img: "assets/img/medevac-slide-03.jpg", titulo: 'Traslado de órganos:', bajada: 'Aseguramos las condiciones de seguridad en el traslado de material biológico humano y equipo de ablación.'},
    {img: "assets/img/medevac-slide-04.jpg", titulo: 'Acompañamiento médico durante vuelos comerciales:', bajada: 'Para pacientes que necesitan viajar acompañados por profesionales de la salud, ofrecemos un servicio de acompañamiento médico.'},
    {img: "assets/img/medevac-slide-01.jpg", titulo: 'Asistencia las 24 horas:', bajada: 'Cuando se trata de una emergencia médica, el tiempo de traslado es fundamental, por eso, nuestros Servicios Aeromédicos garantizan respuesta, disponibilidad y rapidez para casos de urgencia médica o derivación las 24 horas.'},
    {img: "assets/img/medevac-slide-02.jpg", titulo: 'Asistencia Integral:', bajada: 'Además de proporcionar el servicio aeromédico, nos ocupamos también del servicio completo en Argentina y en países limítrofes.'},
    {img: "assets/img/medevac-slide-03.jpg", titulo: 'Traslado de órganos:', bajada: 'Aseguramos las condiciones de seguridad en el traslado de material biológico humano y equipo de ablación.'},
    {img: "assets/img/medevac-slide-04.jpg", titulo: 'Acompañamiento médico durante vuelos comerciales:', bajada: 'Para pacientes que necesitan viajar acompañados por profesionales de la salud, ofrecemos un servicio de acompañamiento médico.'}
  ];

  slides2 = [
    {img: "assets/img/medevac-slide-05.jpg", titulo: 'Equipo médico:', bajada: 'Disponemos de un entrenado equipo de profesionales que cuentan con el curso habilitante de médicos aeroevacuadores certificado por el Instituto Nacional de Medicina Aeronáutica y Espacial.'},
    {img: "assets/img/medevac-slide-06.jpg", titulo: 'Pilotos:', bajada: 'Nuestros pilotos de avión realizan un Curso Periódico Anual con la autoridad aeronáutica local y también un curso teórico y entrenamiento práctico en simulador en EE.UU. denominado Recurrent Anual.'},
    {img: "assets/img/medevac-slide-07.jpg", titulo: 'Base de operaciones:', bajada: 'Contamos con un centro de operaciones que funciona todos los días del año tanto a escala nacional como internacional.'},
    {img: "assets/img/medevac-slide-08.jpg", titulo: 'Flota y Sucursales:', bajada: 'Contar con la flota más completa de móviles propios -para cubrir los distintos grados de complejidad de atención- y con sucursales ubicadas estratégicamente en todo el país son una pieza fundamental que complementa y apoya los traslados aéreos permitiéndonos actuar de inmediato.'}
    
  ];


  slideConfig = {
    "slidesToShow": 3, 
    "slidesToScroll": 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: '0px',
          slidesToShow: 1
        }
      }
    ]
  };
  constructor() { }

  ngOnInit() {
  }

}
