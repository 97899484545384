import { Component, OnInit, ViewEncapsulation  } from '@angular/core';

@Component({
  selector: 'app-medicina-laboral',
  templateUrl: './medicina-laboral.component.html',
  styleUrls: ['./medicina-laboral.component.css'],
  encapsulation: ViewEncapsulation.None

})
export class MedicinaLaboralComponent implements OnInit {


  
  slides = [
    {img: "assets/img/medicina-laboral-pic-2.png", titulo: 'Medicina Laboral', bajada: 'Efectuamos exámenes pre-ocupacionales, periódicos y post laborales en el marco de la Ley 19587 y decretos reglamentarios, en todo el país. Realizamos también exámenes psicotécnicos y evaluaciones ambientales; atención médica en consultorios en nuestro Centro Médico; asesoramiento médico-legal y representación en juntas médicas.'},
    {img: "assets/img/medicina-laboral-pic-1.png", titulo: 'Control de Ausentismo', bajada: 'La visita médica es requerida por las empresas para constatar el diagnóstico y justificar o no una falta por enfermedad. Ofrecemos este servicio,que apunta a la reducción del ausentismo laboral en empresas, las 24 hs. los 365 días del año, en todo el país.'},
    {img: "assets/img/medicina-laboral-pic-3.png", titulo: 'Tercerización de servicios médicos', bajada: 'Realizamos el outsourcing de profesionales de la salud (médicos y enfermeros) requeridos para la gestión de consultorios médicos ubicados en las empresas.'}    
  ];


  slideConfig = {
    "slidesToShow": 3, 
    "slidesToScroll": 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: '0px',
          slidesToShow: 1
        }
      }
    ]
  };

  constructor() { }

  ngOnInit() {
  }

}
