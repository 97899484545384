import { HttpClient } from '@angular/common/http';
import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { API_BASE_URL_JSON } from "src/app/data-wp.service";
import { emailData } from "src/app/shared/emailData";
import { SocioData } from "src/app/shared/socio-data.interface";

@Component({
  selector: "app-solicitud-de-credencial",
  templateUrl: "./solicitud-de-credencial.component.html",
  styleUrls: ["../../socios-gestion-online.component.css"],
})
export class SolicitudDeCredencialComponent implements OnInit {
  form: FormGroup;
  direccionSocio = "CABA | CP 1437 | AV. DIRECTORIO 453 1º PISO";

  constructor(
    public formBuilder: FormBuilder,
    private http: HttpClient,
    private modalService: NgbModal
  ) {}

  @Output() closeModal = new EventEmitter();
  @ViewChild("modalRespuesta", { static: false }) modalRespuesta: ElementRef;

  datosSocio: SocioData;
  modalMessage: string;

  get dirOriginal() {
    return this.contactForm.get("dirOriginal");
  }
  get dirNueva() {
    return this.contactForm.get("dirNueva");
  }

  contactForm = this.formBuilder.group({
    dirNueva: [""],
    radiobutton: ["", [Validators.required]],
  });

  ngOnInit() {
    this.datosSocio = JSON.parse(sessionStorage.getItem("data"));
  }
  radioValue(event) {
    let direccion = this.contactForm.get("dirNueva");

    if (event.value == "2") {
      direccion.clearValidators();
      direccion.setValidators([Validators.required]);
      direccion.updateValueAndValidity();
    }
    if (event.value == "1") {
      direccion.reset();
      direccion.clearValidators();
      direccion.updateValueAndValidity();
    }
  }

  submitForm() {
    let formData = new FormData();
    let form = this.contactForm.value;
    formData.append("numero_afiliado", this.datosSocio.socio_numero);
    formData.append("nombre_afiliado", this.datosSocio.socio_nombre);
    formData.append("apellido_afiliado", this.datosSocio.socio_apellido);
    formData.append("email_afiliado", this.datosSocio.socio_email);
    formData.append("dominio_afiliado", this.datosSocio.socio_dominio);
    if (form.radiobutton == "1") {
      formData.append("nuevadireccion", this.direccionSocio);
    }
    if (form.radiobutton == "2") {
      formData.append("nuevadireccion", form.dirNueva);
    }

    this.datosSocio.socio_dominio.length > 0
      ? formData.append(
          "email_destino",
          emailData[this.datosSocio.socio_dominio]
        )
      : formData.append("email_destino", "cas@vittal.com.ar");

    this.http
      .post<any>(
        API_BASE_URL_JSON + "/contact-form-7/v1/contact-forms/3801/feedback",
        formData
      )
      .subscribe((res) => {
        this.closeModal.emit();
        this.modalMessage = res.message;
        this.openModal(this.modalRespuesta);
      });
  }

  openModal(content) {
    this.modalService.open(content, {
      size: "lg",
      centered: true,
      windowClass: "modal-socios-form",
    });
  }
}
