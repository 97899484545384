import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataWpServiceFaqs } from '../../data-wp.service';
import { PostI } from '../../post.interface';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import { Observable  } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: "app-preguntas-frecuentes",
  templateUrl: "./preguntas-frecuentes.component.html",
  styleUrls: ["./preguntas-frecuentes.component.css"],
  providers: [DataWpServiceFaqs],
  encapsulation: ViewEncapsulation.None,
})
export class PreguntasFrecuentesComponent implements OnInit {
  product;
  title;
  posts$: Observable<PostI[]>;
  isMobile: boolean;
  posts: PostI[];

  constructor(
    private postsService: DataWpServiceFaqs,
    private router: Router,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    private metaService: Meta
  ) {}

  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnInit() {
    this.postsService.getPosts().subscribe(
      (posts: PostI[]) => {
        this.posts = posts;
        console.log(this.posts);
      },
      (err: HttpErrorResponse) =>
        err.error instanceof Error
          ? console.log("An error occurred:", err.error.message)
          : console.log(
              `Backend returned code ${err.status}, body was: ${err.error}`
            )
    );

    this.posts$ = this.postsService.getPosts();
    this.posts$.subscribe((data) => {
      /*Levantamos MetaTags*/
      var titleSeo = (this.product = data[0].acf.seo.titulo);
      var descripcionSeo = (this.product = data[0].acf.seo.descripcion);
      var keywordsSeo = (this.product = data[0].acf.seo.keywords);
      /*Seteamos Metatags en header*/
      this.titleService.setTitle("Vittal - " + titleSeo);
      this.metaService.updateTag({
        name: "description",
        content: descripcionSeo,
      });
      this.metaService.updateTag({ name: "keywords", content: keywordsSeo });
      this.metaService.updateTag({ name: "robots", content: "index, follow" });
    });
    this.isMobile = window.innerWidth < 480 ? true : false;
  }
  selectPost(slug) {
    this.router.navigate([slug]);
  }
}
